<template>
  <v-dialog :value="open" max-width="1024px" @click:outside="close">
    <v-card class="pa-2">
      <v-card-title>
        <span class="headline font-weight-light">
          {{ totalFluxItems }} résultat<span v-if="totalFluxItems > 1">s</span>
        </span>
      </v-card-title>
      <v-card-text>
        <TSFluxItemsTable
          :loading="fluxItemsLoading"
          :fluxItems="fluxItems"
          :totalFluxItems="totalFluxItems"
          @optionsChange="onOptionsChange"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="primary" class="text-none" @click="close">
          Fermer
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import TSFluxItemsTable from '@/components/flux/TSFluxItemsTable'
export default {
  name: 'TSUserDialogTester',
  components: {
    TSFluxItemsTable,
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    flux: {
      type: Object,
      default: () => ({
        customData: {},
      }),
    },
  },
  data() {
    return {
      dflux: { settings: {} },
      fluxItemsLoading: true,
      options: null,
    }
  },
  computed: {
    ...mapGetters({
      fluxItems: 'fluxItems/fluxItems',
      totalFluxItems: 'fluxItems/totalFluxItems',
    }),
  },
  watch: {
    open(val) {
      if (val && this.options) {
        this.onOptionsChange(this.options)
      }
    },
    flux(val) {
      this.dflux = val
    },
  },
  methods: {
    ...mapActions({
      getFluxItems: 'fluxItems/getFluxItems',
    }),
    close() {
      this.$emit('close', {})
    },
    async onOptionsChange(options) {
      this.options = options
      const params = {
        fluxId: this.flux.id,
        page: options.page - 1,
        size: options.itemsPerPage,
      }
      if (options.sortBy.length) {
        params.order = []
        options.sortBy.forEach((e, i) => {
          params.order.push([e, options.sortDesc[i] ? 'DESC' : 'ASC'])
        })
      }

      this.fluxItemsLoading = true
      await this.getFluxItems({ params })
      this.fluxItemsLoading = false
    },
  },
}
</script>

<style lang="scss" scoped>
.f-list-item {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 4px;
  margin: 4px;
  cursor: pointer;
  height: 32px;
  line-height: 32px;
  text-align: center;
  background: rgba(20, 97, 126, 0.05);
}
</style>
