import Vue from 'vue'
import { log, logSync } from '@/decorators'
import { findIndex } from 'lodash-es'
import userApi from '@/api/userApi'

// initial state
const state = () => ({
  users: [],
  validators: [],
  operators: [],
  totalUsers: 0,
  totalPages: 0,
  currentPage: 0,
})

// getters
const getters = {
  users: (state) => {
    return state.users
  },
  operators: (state) => {
    return state.operators
  },
  validators: (state) => {
    return state.validators
  },
  totalUsers: (state) => {
    return state.totalUsers
  },
  totalPages: (state) => {
    return state.totalPages
  },
  currentPage: (state) => {
    return state.currentPage
  },
}
/* eslint-disable */
// actions
const actions = {
  @log('STORE - ACTION - USERS - GET USERS', 3)
  async getUsers({ commit, state }, params) {
    const res = await userApi.getUsers(params)
    commit('SET_STATE', res)
  },
  @log('STORE - ACTION - USERS - GET OPERATORS', 3)
  async getOperators({ commit, state }) {
    const res = await userApi.getUsers({
      params: {
        search: 'OPERATOR',
        size: 1000
      }
    })
    commit('SET_OPERATORS', res)
  },
  @log('STORE - ACTION - USERS - GET VALIDATORS', 3)
  async getValidators({ commit, state }, params) {
    const res = await userApi.getUsers({
      params: {
        search: 'VALIDATOR',
        size: 1000
      }
    })
    commit('SET_VALIDATORS', res)
  },
  @log('STORE - ACTION - USERS - CREATE USER', 3)
  async createUser({ commit, state }, params) {
    const res = await userApi.createUser(params)
    commit('ADD_NEW_USER', res.user)
    return res.user
  },
  @log('STORE - ACTION - USERS - UPDATE USER', 3)
  async updateUser({ commit, state }, params) {
    const res = await userApi.updateUser(params)
    commit('SET_USER_IN_LIST', params.user)
    return res
  },
  @log('STORE - ACTION - USERS - DELETE USER', 3)
  async deleteUser({ commit, state }, params) {
    const res = await userApi.deleteUser(params)
    commit('REMOVE_USER_IN_LIST', params.id)
    return res
  }
}

// mutations
const mutations = {
  @logSync('STORE - MUTATION - USERS - SET USERS', 4)
  SET_STATE(state, res) {
    state.users = res.users
    state.totalUsers = res.totalItems
    state.totalPages = res.totalPages
    state.currentPage = res.currentPage
  },
  @logSync('STORE - MUTATION - USERS - SET OPERATORS', 4)
  SET_OPERATORS(state, res) {
    state.operators = res.users
  },
  @logSync('STORE - MUTATION - USERS - SET VALIDATORS', 4)
  SET_VALIDATORS(state, res) {
    state.validators = res.users
  },
  @logSync('STORE - MUTATION - USERS - ADD USER IN LIST', 4)
  ADD_NEW_USER(state, user) {
    state.users.unshift(user)
  },
  @logSync('STORE - MUTATION - USERS - SET USER IN LIST', 4)
  SET_USER_IN_LIST(state, user) {
    const index = findIndex(state.users, u => { return u.id === user.id })
    Vue.set(state.users, index, user)
  },
  @logSync('STORE - MUTATION - USERS - REMOVE USER IN LIST', 4)
  REMOVE_USER_IN_LIST(state, id) {
    const index = findIndex(state.users, u => { return u.id === id })
    state.users.splice(index, 1)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
