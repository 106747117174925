<template>
  <div>
    <v-row class="fill-height" v-if="loading">
      <v-col cols="12" class="d-flex">
        <v-progress-linear indeterminate color="white" />
      </v-col>
    </v-row>
    <div v-else>
      <v-col cols="12" class="mt-5 text-h4 font-weight-light d-flex">
        <div class="white--text">
          <span class="f-title">{{ currentFlux.name }}</span>
          <br />
          <small class="ml-2 mt-4 f-desc">{{ currentFlux.description }}</small>
        </div>
        <v-spacer />
        <v-btn
          v-if="showReset"
          color="red"
          class="text-none"
          dark
          @click="resetFlux"
        >
          <v-icon small left>mdi-alert-octagon</v-icon>
          Reset du Flux
        </v-btn>
      </v-col>
      <draggable
        :list="widgets"
        handle=".handle"
        group="widgets"
        class="row px-3"
      >
        <t-s-widget
          v-for="w in widgets"
          v-bind="w"
          :fluxItem="{}"
          :key="w.id"
          :isSplit="split"
          @refresh="onRefresh"
          @split="onSplit"
          @loaded="onLoaded"
        />
      </draggable>
      <v-btn
        v-show="split"
        id="unsplit-btn"
        color="orange"
        dark
        @click="onSplit(false)"
        fab
        transition="scale-transition"
      >
        <v-icon>mdi-arrow-collapse-vertical</v-icon>
      </v-btn>
    </div>
    <t-s-photo-gallery />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TSCommonMixin } from '@/mixins/TSCommonMixin'
import draggable from 'vuedraggable'
import TSPhotoGallery from '@/components/common/TSPhotoGallery'
import TSWidget from '@/components/flux/widgets/TSWidget'
export default {
  name: 'FluxWork',
  components: {
    draggable,
    TSPhotoGallery,
    TSWidget,
  },
  mixins: [TSCommonMixin],
  data: () => ({
    loading: true,
    split: false,
    isMapboxReady: false,
    isStreetViewReady: false,
    widgets: [
      {
        id: 'browser',
        title: 'Items du flux',
        type: 'browser',
        cols: 12,
        isExpandable: true,
        splitStyle: {},
      },
      {
        id: 'operatorChart',
        title: 'Suivi Opérateurs',
        type: 'operatorChart',
        cols: 12,
        isExpandable: true,
        splitStyle: {},
      },
    ],
  }),
  created() {
    this.load(this.$route.params.id)
  },
  destroyed() {
    this.setCurrentFlux(null)
  },
  watch: {
    $route(to) {
      this.load(to.params.id)
    },
  },
  computed: {
    ...mapGetters({
      currentFlux: 'flux/currentFlux',
    }),
    isReady() {
      return this.isMapboxReady && this.isStreetViewReady
    },
    showReset() {
      return process.env.VUE_APP_RESET_FLUX_ENABLED
    },
  },
  methods: {
    ...mapActions({
      getFluxDetails: 'flux/getFluxDetails',
      resetFluxDetails: 'flux/resetDetails',
      resetAllFluxItems: 'flux/resetAllFluxItems',
      getFluxStats: 'stats/getFluxStats',
    }),
    ...mapMutations({
      setCurrentFlux: 'flux/SET_CURRENT_FLUX',
    }),
    async load(fluxId) {
      this.msg = ''
      this.loading = true
      await this.getFluxDetails({ id: fluxId })
      this.getFluxStats({ fluxId })

      this.loading = false
    },
    onLoaded(type) {
      console.log(type)
    },
    onRefresh(type) {
      console.log(type)
    },
    onSplit(v) {
      this.split = v
    },
    resetFlux() {
      this.$store
        .dispatch('confirmation/askConfirmation', {
          title: 'Voulez-vous vraiment réinitialiser le flux ?',
          body: 'With great power, comes great responsibility',
        })
        .then(async (confirmation) => {
          if (confirmation) {
            await this.resetAllFluxItems()
            await this.load(this.$route.params.id)
          }
        })
    },
  },
}
</script>

<style lang="scss" scoped>
#unsplit-btn {
  position: fixed;
  bottom: 10px;
  left: 25px;
  z-index: 200;
}
.f-title {
  font-size: 1.5rem !important;
  display: inline-block;
  vertical-align: middle;
}
.f-desc {
  display: inline-block;
  line-height: 1.3em;
  max-width: 500px;
  font-size: 16px;
}
</style>
