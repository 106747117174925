const $axios = require('axios')
import { log } from '@/decorators'
/* eslint-disable */
export default {
  endPoint: [
    `/${process.env.VUE_APP_API_VERSION}/teams`, 
    `/${process.env.VUE_APP_API_VERSION}/teams/:id/users/:userId`
  ],
  client: $axios.create({
    withCredentials: true,
    baseURL: process.env.VUE_APP_API_URL,
    timeout: process.env.VUE_APP_API_TIMEOUT
  }),
  @log('TEAMS - API - GET TEAMS', 3)
  async getTeams(params) {
    const res = await this.client.get(`${this.endPoint[0]}`, params)
    return res.data
  },
  @log('TEAMS - API - GET TEAM DETAILS', 3)
  async getTeamDetails(params) {
    const res = await this.client.get(`${this.endPoint[0]}/${params.id}`)
    return res.data
  },
  @log('TEAMS - API - CREATE TEAM', 3)
  async createTeam(params) {
    const res = await this.client.post(`${this.endPoint[0]}`, { team: params.team })
    return res.data
  },
  @log('TEAMS - API - UPDATE TEAM', 3)
  async updateTeam(params) {
    const res = await this.client.patch(`${this.endPoint[0]}/${params.team.id}`, params.team)
    return res.data
  },
  @log('TEAMS - API - DELETE TEAM', 3)
  async deleteTeam(params) {
    const res = await this.client.delete(`${this.endPoint[0]}/${params.id}`)
    return res
  }
}