<template>
  <div class="ts-flux-items-table-container mt-6">
    <v-data-table
      :headers="headers"
      :items="fluxItems"
      :search="search"
      :options.sync="options"
      :server-items-length="totalFluxItems"
      :loading="loading"
      class="elevation-1"
      :single-expand="true"
      :expanded.sync="expanded"
      show-expand
      :footer-props="{
        itemsPerPageOptions: [10, 50, 100, 500, 1000],
      }"
    >
      <template v-slot:[`item.origin`]="{ item }">
        <v-btn
          text
          small
          :to="{
            name: isDuplicateFlux ? 'FluxDuplicateWork' : 'FluxBrowserWork',
            params: { itemId: item.origin },
          }"
          >{{ item.origin }}</v-btn
        >
      </template>
      <template v-slot:[`item.id`]="{ item }">
        <v-btn
          text
          small
          :to="{
            name: isDuplicateFlux ? 'FluxDuplicateWork' : 'FluxBrowserWork',
            params: { itemId: item.id },
          }"
          >{{ item.id }}</v-btn
        >
      </template>

      <template v-slot:[`item.structuredData.isActive`]="{ item }">
        <v-chip
          v-if="item.structuredData.isActive"
          color="secondary"
          small
          outlined
          label
          dark
        >
          <v-icon>mdi-play</v-icon>
        </v-chip>
        <v-chip v-else color="red" small outlined label dark>
          <v-icon>mdi-stop</v-icon>
        </v-chip>
      </template>
      <template v-slot:[`item.state`]="{ item }">
        <v-chip :color="fluxItemsStates[item.state].color" small label dark>
          {{ fluxItemsStates[item.state].label }}
        </v-chip>
      </template>
      <template v-slot:[`item.finalAddress`]="{ item }">
        <small>{{ item.finalAddress }}</small>
      </template>
      <template v-slot:[`item.treatedOrSkippedAt`]="{ item }">
        {{ formatDate(item.treatedOrSkippedAt) }}
      </template>
      <template v-slot:[`item.validatedOrRejectedAt`]="{ item }">
        {{ formatDate(item.validatedOrRejectedAt) }}
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <pre
            class="py-3"
            v-html="
              syntaxHighlight(JSON.stringify(item.structuredData, null, 4))
            "
          ></pre>
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { format, parseISO } from 'date-fns'
import { get } from 'lodash-es'
import { fluxItemsStates } from '@/helpers/fluxItemsHelpers'
export default {
  name: 'TSFluxItemsTable',
  props: {
    flux: {
      type: Object,
      default: () => {},
    },
    fluxItems: {
      type: Array,
      default: () => [],
    },
    totalFluxItems: {
      type: Number,
      default: 0,
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      expanded: [],
      headers: [
        {
          text: 'ID',
          align: 'start',
          sortable: true,
          value: 'id',
        },
        {
          text: 'Origine',
          align: 'start',
          sortable: true,
          value: 'origin',
        },
        {
          text: 'Active',
          sortable: false,
          value: 'structuredData.isActive',
        },
        { text: 'État', value: 'state' },
        { text: 'Insee', value: 'codeInsee' },
        { text: 'Adresse', value: 'finalAddress' },
        { text: 'Opérateur', value: 'treatedOrSkippedBy' },
        { text: 'Validateur', value: 'validatedOrRejectedBy' },
        { text: 'Traité ou passé le', value: 'treatedOrSkippedAt' },
        { text: 'Validé ou rejeté le', value: 'validatedOrRejectedAt' },
        { text: 'Tps opérateur', value: 'operatorTimeSpent' },
        { text: 'Tps validateur', value: 'validatorTimeSpent' },
        { text: '', value: 'data-table-expand' },
      ],
      options: {
        search: '',
        itemsPerPage: 10,
      },
      search: '',
      fluxItemsStates,
    }
  },
  watch: {
    options: {
      handler() {
        this.$emit('optionsChange', this.options)
      },
      deep: true,
    },
  },
  computed: {
    isDuplicateFlux() {
      return get(this.flux, 'settings.options.isARemoveDuplicateFlux')
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return 'N.C'
      return format(parseISO(date), 'dd/MM/yyyy')
    },
    syntaxHighlight(json) {
      json = json
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
      return json.replace(
        /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g,
        (match) => {
          let cls = 'number'
          if (/^"/.test(match)) {
            if (/:$/.test(match)) {
              cls = 'key'
            } else {
              cls = 'string'
            }
          } else if (/true|false/.test(match)) {
            cls = 'boolean'
          } else if (/null/.test(match)) {
            cls = 'null'
          }
          return '<span class="' + cls + '">' + match + '</span>'
        }
      )
    },
  },
}
</script>
<style lang="scss">
pre {
  .key {
    font-weight: bold;
    margin-left: 20px;
    color: #14617e;
  }
}
</style>
<style lang="scss" scoped>
.ts-flux-items-table-container {
  width: 100%;
}
pre {
  white-space: pre-line;
}
</style>
