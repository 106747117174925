<template>
  <v-row class="pa-0 fill-height" :style="{ 'min-height': '450px' }" no-gutters>
    <v-col
      v-if="matches && !matches.length"
      cols="12"
      class="pa-0 ma-4 text-center"
    >
      <h3>Aucun candidat trouvé</h3>
      <v-btn
        v-if="isValidator"
        class="ma-3"
        color="warning"
        @click="skipFluxItem"
        >PASSER</v-btn
      >
    </v-col>
    <v-col v-else id="matches-list" cols="12" class="pa-0 ma-0">
      <v-list v-if="show" two-line dense rounded>
        <v-list-item-group
          v-if="!disableMultiSelection"
          v-model="selected"
          active-class="primary--text"
          multiple
        >
          <template v-for="(m, index) in matches">
            <v-list-item
              :key="`match-${m.id}`"
              :value="m"
              :disabled="m.rejected && !isAdmin"
            >
              <template v-slot:default="{}">
                <v-list-item-avatar v-if="selectedIndex(m) !== -1">
                  <v-chip
                    @click.stop="setCurrentMatch(m)"
                    small
                    class="ma-0"
                    color="primary"
                    text-color="white"
                  >
                    {{ selectedIndex(m) + 1 }}
                  </v-chip>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    <b
                      class="primary--text"
                      :class="{ 'text-decoration-line-through': m.rejected }"
                      >{{ title(m) }}</b
                    >
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <small
                      :class="{
                        'text-decoration-line-through': m.rejected,
                        full: ['human', 'address'].includes(m.method),
                      }"
                      >{{ subtitle(m) }}</small
                    >
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    <v-btn small color="secondary" text @click.stop="goTo3D(m)">
                      <v-icon small class="mr-3">mdi-eye</v-icon> 3D</v-btn
                    >
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-btn v-if="!m.rejected || isAdmin" small icon>
                    <v-icon
                      small
                      @click.stop="validateMatch(m)"
                      color="secondary"
                      >mdi-check-outline</v-icon
                    >
                  </v-btn>
                  <v-btn v-if="!m.rejected || isAdmin" small icon>
                    <v-icon
                      small
                      @click.stop="rejectMatch(m)"
                      color="red lighten-1"
                      >mdi-delete</v-icon
                    >
                  </v-btn>
                </v-list-item-action>
              </template>
            </v-list-item>
            <v-divider
              class="mb-1"
              v-if="index < matches.length - 1"
              :key="index"
            ></v-divider>
          </template>
        </v-list-item-group>
        <div v-else>
          <v-list-item
            v-for="m in matches"
            :key="`match-${m.id}`"
            :value="m"
            :disabled="m.rejected && !isAdmin"
            @click.stop="selectMatch(m)"
          >
            <template v-slot:default="{}">
              <v-list-item-avatar v-if="m.id === selectedId">
                <v-icon color="primary">mdi-eye</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  <b
                    class="primary--text"
                    :class="{ 'text-decoration-line-through': m.rejected }"
                    >{{ title(m) }}</b
                  >
                </v-list-item-title>
                <v-list-item-subtitle>
                  <small
                    :class="{
                      'text-decoration-line-through': m.rejected,
                      full: ['human', 'address'].includes(m.method),
                    }"
                    >{{ subtitle(m) }}</small
                  >
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  <v-btn small color="secondary" text @click.stop="goTo3D(m)">
                    <v-icon small class="mr-3">mdi-eye</v-icon> 3D</v-btn
                  >
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn v-if="!m.rejected || isAdmin" small icon>
                  <v-icon small @click.stop="validateMatch(m)" color="secondary"
                    >mdi-check-outline</v-icon
                  >
                </v-btn>
                <v-btn v-if="!m.rejected || isAdmin" small icon>
                  <v-icon
                    small
                    @click.stop="rejectMatch(m)"
                    color="red lighten-1"
                    >mdi-delete</v-icon
                  >
                </v-btn>
              </v-list-item-action>
            </template>
          </v-list-item>
        </div>
      </v-list>
    </v-col>
    <v-dialog
      v-model="openAddressDialog"
      content-class="e-dialog"
      persistent
      max-width="700"
    >
      <v-card class="pa-3">
        <v-form ref="addressForm" v-model="addressValid">
          <v-card-title>
            <h2 class="headline">Saisir une adresse manuellement</h2>
            <v-spacer></v-spacer>
            <v-btn icon @click.stop="openAddressDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <b>ex</b>: 23 rue Faventines, 26000 VALENCE<br /><br />
            <v-text-field
              v-model="address"
              :rules="addressRules"
              @update:error="() => {}"
            >
            </v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="indigo--text font-weight-regular text-none text--darken-4"
              text
              @click.stop="openAddressDialog = false"
            >
              Annuler
            </v-btn>
            <v-btn
              class="text-none font-weight-regular"
              color="warning"
              :ripple="false"
              @click.stop="unknownAddress"
            >
              Inconnu
            </v-btn>
            <v-btn
              :disabled="!addressValid"
              class="text-none font-weight-regular"
              color="secondary"
              :ripple="false"
              @click.stop="confirmAddress"
            >
              Confirmer
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { findIndex, get } from 'lodash-es'
export default {
  name: 'TSMatchesWidget',
  props: {
    matches: {
      type: Array,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  components: {},
  data() {
    return {
      address: '',
      openAddressDialog: false,
      show: true,
      selectedId: '',
      addressValid: true,
      addressRules: [
        (v) => !!v || 'adresse requise',
        (v) => /^\d/.test(v) || 'Numéro de rue absent',
        (v) => /([0-9]{5})/.test(v) || 'Code postal absent',
      ],
    }
  },
  created() {},
  destroy() {},
  watch: {
    currentMatch(newMatch) {
      if (newMatch) {
        this.selected = [newMatch]
        this.selectedId = newMatch.id
      }
    },
  },
  computed: {
    ...mapGetters({
      streetViewData: 'matches/streetViewData',
      fluxItem: 'fluxItems/currentFluxItem',
      selectedMatches: 'matches/selectedMatches',
      currentMatch: 'matches/currentMatch',
      currentFlux: 'flux/currentFlux',
    }),
    selected: {
      get() {
        return this.selectedMatches
      },
      set(matches) {
        console.log(matches)
        this.setSelectedMatches(matches)
      },
    },
    isAdmin() {
      return get(this.user, 'customData.role') === 'ADMIN'
    },
    isValidator() {
      return get(this.user, 'customData.role') === 'VALIDATOR'
    },
    disableMultiSelection() {
      return get(this, 'currentFlux.settings.options.disableMultiSelection')
    },
    fluxOptions() {
      return get(this, 'currentFlux.settings.options') || {}
    },
  },
  methods: {
    ...mapActions({
      reloadMatches: 'fluxItems/reloadMatches',
      createMatch: 'matches/createMatch',
      updateMatch: 'matches/updateMatch',
      updateFluxItem: 'fluxItems/updateFluxItem',
    }),
    ...mapMutations({
      removeMatch: 'matches/REMOVE_FROM_SELECTED_MATCHES',
      setCurrentMatch: 'matches/SET_CURRENT_MATCH',
      setSelectedMatches: 'matches/SET_SELECTED_MATCHES',
    }),
    title(match) {
      if (match.method === 'land') {
        return `Parcelle - ${match.customData.id}`
      } else if (match.method === 'human') {
        return `Human - ${match.customData.by}`
      } else if (match.method === 'exact') {
        return `Selon l'annonce`
      } else if (match.method === 'dvf') {
        return `DVF - Parcelle - ${match.customData.id}`
      } else if (match.method === 'address') {
        return `Adresse possible`
      } else {
        return match.score
      }
    },
    selectMatch(match) {
      if (match.id === this.selectedId) {
        this.selected = []
        this.setCurrentMatch(null)
        this.selectedId = ''
      } else {
        this.selected = [match]
        this.setCurrentMatch(match)
        this.selectedId = match.id
      }
    },
    subtitle(match) {
      if (['land', 'exact', 'dvf'].includes(match.method)) {
        return `Score : ${match.score ? match.score.toFixed(2) : 0} - Sup: ${
          match.customData.area
        } m²`
      } else if (match.method === 'human') {
        return `Score : ${match.score ? match.score.toFixed(2) : 0} - Sup: ${
          match.customData.area
        } m² - ${
          match.customData.hint ? match.customData.hint : 'Pas de détail'
        }`
      } else if (match.method === 'address') {
        return match.customData.address
      } else {
        return match.score
      }
    },
    selectedIndex(match) {
      return findIndex(this.selected, (s) => {
        return s.customData.id === match.customData.id
      })
    },
    async validateMatch(match) {
      if (this.fluxItem.state === 'validated') {
        const fluxItem = {
          id: this.fluxItem.id,
          finalAddress: match.customData.address,
          addressSetByUser: false,
        }
        await this.updateFluxItem({ fluxItem })
        this.$root.$emit('next', 'validated', false)
      } else if (this.fluxItem.state === 'treated') {
        this.$root.$emit('next', 'validated')
      } else if (
        this.fluxItem.state === 'created' ||
        get(this.user, 'customData.role') === 'ADMIN'
      ) {
        if (
          this.fluxOptions.disableProofMode ||
          get(this.user, 'customData.role') === 'ADMIN'
        ) {
          this.$store
            .dispatch('confirmation/askConfirmation', {
              title: 'Confirmer le candidat',
              body: 'Merci de laisser des indices pour aider le validateur',
              showReason: true,
            })
            .then(async (confirmation) => {
              if (confirmation) {
                await this.createMatch({
                  match: {
                    method: 'human',
                    score: 100,
                    fluxItemId: match.fluxItemId,
                    geom: match.geom,
                    geomCenter: match.geomCenter,
                    customData: {
                      ...match.customData,
                      ...{
                        by: this.user.id,
                        hint: confirmation.reason,
                        streetViewData: this.streetViewData,
                      },
                    },
                  },
                })
                this.$root.$emit('next', 'treated')
              }
            })
        } else {
          this.$root.$emit('proofMode', true, match)
        }
      }
    },
    async skipFluxItem() {
      this.$root.$emit('next', 'skipped', false)
    },
    async rejectMatch(match) {
      if (match.method === 'address') {
        this.openAddressDialog = true
        this.address = match.customData.address
      } else {
        await this.updateMatch({
          id: match.id,
          match: {
            rejected: true,
          },
        })

        this.removeMatch(match)
        this.show = false
        this.show = true

        if (this.fluxItem.state === 'treated') {
          this.$root.$emit('next', 'rejected')
        }
      }
    },
    async unknownAddress() {
      const fluxItem = {
        id: this.fluxItem.id,
        finalAddress: 'INCONNUE',
        addressSetByUser: true,
      }

      await this.updateFluxItem({ fluxItem })
      this.$root.$emit('next', 'validated', false)
    },
    async confirmAddress() {
      const finalAddress =
        this.address.trim() !== '' ? this.address : 'INCONNUE'
      const fluxItem = {
        id: this.fluxItem.id,
        finalAddress,
        addressSetByUser: true,
      }

      await this.updateFluxItem({ fluxItem })
      this.$root.$emit('next', 'validated', false)
    },
    goTo3D(m) {
      const lat = m.geomCenter.coordinates[1]
      const lon = m.geomCenter.coordinates[0]
      const url = `https://www.google.com/maps/@${lat},${lon},100m/data=!3m1!1e3`
      window.open(url, '_blank')
    },
  },
}
</script>
<style lang="scss" scoped>
.full {
  white-space: normal;
}
</style>
