const $axios = require('axios')
import { log } from '@/decorators'
/* eslint-disable */
export default {
  endPoint: [`/${process.env.VUE_APP_API_VERSION}/matches`],
  client: $axios.create({
    withCredentials: true,
    baseURL: process.env.VUE_APP_API_URL,
    timeout: process.env.VUE_APP_API_TIMEOUT
  }),
  @log('MATCH - API - CREATE MATCH', 3)
  async createMatch(params) {
    const res = await this.client.post(`${this.endPoint[0]}`, { match: params.match })
    return res.data
  },
  @log('MATCHS - API - UPDATE MATCH', 3)
  async updateMatch(params) {
    const res = await this.client.patch(`${this.endPoint[0]}/${params.id}`, params.match)
    return res.data
  }
}