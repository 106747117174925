<template>
  <v-row
    class="pa-0 ma-0 fill-height elevation-1 white"
    :style="{ 'min-height': '600px' }"
    no-gutters
  >
    <v-progress-linear v-if="loading" id="map-loader" :indeterminate="true" />
    <v-col id="map" cols="12" class="pa-0 ma-0">
      <MglMap
        :accessToken="accessToken"
        :mapStyle="mapStyle"
        :center="[2.2096669, 46.232192]"
        :zoom="4.4"
        @load="onLoad"
        :attributionControl="false"
      >
        <MglNavigationControl position="top-right" />
        <MglRasterLayer
          :sourceId="'bdOrtho'"
          :source="bdOrtho"
          :layerId="bdOrthoTiles.id"
          :layer="bdOrthoTiles"
        />
        <MglGeojsonLayer
          :sourceId="'dept'"
          :source="deptSource"
          :layerId="deptsBoundaries.id"
          :layer="deptsBoundaries"
          @click="onDeptClick"
        />
        <MglGeojsonLayer
          :sourceId="'deptPoint'"
          :source="deptPoint"
          :layerId="deptLayer.id"
          :layer="deptLayer"
          @mouseenter="onDeptEnter"
          @mouseleave="onDeptLeave"
        />
        <MglGeojsonLayer
          :sourceId="'deptBoundary'"
          :source="deptBoundarySource"
          :layerId="deptStroke.id"
          :layer="deptStroke"
        />
        <MglGeojsonLayer
          :sourceId="'deptBoundary'"
          :source="deptBoundarySource"
          :layerId="deptPolygon.id"
          :layer="deptPolygon"
          @click="outsideClick"
        />
        <MglGeojsonLayer
          :sourceId="'items'"
          :source="itemsSource"
          :layerId="items.id"
          :layer="items"
          @mouseenter="onItemEnter"
          @mouseleave="onItemLeave"
          @click="onItemClick"
        />
        <MglGeojsonLayer
          :sourceId="'parcelles'"
          :source="parcellesSource"
          :layerId="parcelles.id"
          :layer="parcelles"
        />
      </MglMap>
    </v-col>
    <div class="total-ads">Total : {{ totatAds }}</div>
    <div class="active-only">
      <v-switch
        dark
        dense
        hide-details
        class="ma-2 white--text"
        label="Annonces encore actives"
        :input-value="activeOnly"
        @change="toggleActiveOnly"
      />
    </div>
  </v-row>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import clientApi from '@/api/clientApi'
import promisify from 'map-promisified'
import { filter, find, map as Map } from 'lodash-es'
import Mapbox from 'mapbox-gl'
import bbox from '@turf/bbox'
import mask from '@turf/mask'
import centroid from '@turf/centroid'

import {
  MglMap,
  MglGeojsonLayer,
  MglNavigationControl,
  MglRasterLayer,
} from 'vue-mapbox'

export default {
  name: 'TSDashboardMap',
  props: {},
  components: {
    MglMap,
    MglGeojsonLayer,
    MglNavigationControl,
    MglRasterLayer,
  },
  data() {
    return {
      accessToken: process.env.VUE_APP_MB_ACCESS_TOKEN,
      lastZoom: 8,
      mapStyle: 'mapbox://styles/mapbox/light-v10',
      mapbox: null,
      emptyFeatures: { type: 'FeatureCollection', features: [] },
      itemsSource: {
        type: 'geojson',
        data: { type: 'FeatureCollection', features: [] },
      },
      parcellesSource: {
        type: 'geojson',
        data: { type: 'FeatureCollection', features: [] },
      },
      deptSource: {
        type: 'geojson',
        data: { type: 'FeatureCollection', features: [] },
      },
      deptPoint: {
        type: 'geojson',
        data: { type: 'FeatureCollection', features: [] },
      },
      deptBoundarySource: {
        type: 'geojson',
        data: { type: 'FeatureCollection', features: [] },
      },
      useBdOrtho: false,
      bdOrtho: {
        type: 'raster',
        tiles: [
          'https://wxs.ign.fr/an7nvfzojv5wa96dsga5nk8w/geoportail/wmts?layer=ORTHOIMAGERY.ORTHOPHOTOS&style=normal&tilematrixset=PM&Service=WMTS&Request=GetTile&Version=1.0.0&Format=image%2Fjpeg&TileMatrix={z}&TileCol={x}&TileRow={y}',
        ],
        tileSize: 256,
        attribution: 'bdOrtho',
      },
      bdOrthoTiles: {
        id: 'bdOrthoTiles',
        type: 'raster',
        source: 'bdOrtho',
        minzoom: 14,
        layout: {
          visibility: this.useBdOrtho ? 'visible' : 'none',
        },
      },
      items: {
        id: 'items',
        type: 'circle',
        source: 'items',
        paint: {
          'circle-radius': 6,
          'circle-color': [
            'match',
            ['get', 'source'],
            'leboncoin',
            '#FF6E14',
            'bienici',
            '#FBBA20',
            'seloger',
            '#E00030',
            'bellesdemeures',
            '#AED581',
            '#ccc',
          ],
          'circle-stroke-width': 2,
          'circle-stroke-color': [
            'match',
            ['to-string', ['get', 'isActive']],
            'true',
            '#34c2b8',
            'false',
            '#ff5252',
            '#fff',
          ],
        },
        filter: ['==', '$type', 'Point'],
      },
      parcelles: {
        id: 'parcelles',
        type: 'line',
        source: 'parcelles',
        minzoom: 14,
        paint: {
          'line-width': 2,
          'line-color': [
            'match',
            ['to-string', ['get', 'isActive']],
            'true',
            '#34c2b8',
            'false',
            '#ff5252',
            /* other */ '#ccc',
          ],
        },
      },
      deptsBoundaries: {
        id: 'deptsBoundaries',
        type: 'fill',
        source: 'dept',
        paint: {
          'fill-color': {
            property: 'total',
            stops: [
              [0, '#fff'],
              [200, '#ff9800'],
              [2000, '#fb0000'],
            ],
          },
          'fill-outline-color': '#14617e',
        },
      },
      deptLayer: {
        id: 'deptLayer',
        type: 'symbol',
        source: 'deptPoint',
        paint: {
          'text-color': '#ffffff',
          'text-halo-color': 'rgba(0, 0, 0, 0.7)',
          'text-halo-width': 1,
          'text-halo-blur': 1,
        },
        layout: {
          'text-field': ['get', 'total'],
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12,
        },
      },
      deptPolygon: {
        id: 'hintPolygon',
        type: 'fill',
        source: 'deptBoundary',
        paint: {
          'fill-color': 'rgba(20, 97, 126, 0.5)',
          'fill-outline-color': '#14617e',
        },
      },
      deptStroke: {
        id: 'hintStroke',
        type: 'line',
        source: 'deptBoundary',
        paint: {
          'line-width': 2,
          'line-color': '#83F2BE',
        },
      },
      popup: new Mapbox.Popup({
        closeButton: false,
        closeOnClick: false,
      }),
      selectedDept: null,
      parcellesFeatures: null,
      totatAds: 0,
      activeOnly: true,
      loading: true,
    }
  },
  mounted() {
    this.mapbox = Mapbox
    this.$root.$on('resizeMap', this.resize)
  },
  destroyed() {
    this.$root.$off('resizeMap', this.resize)
  },
  watch: {},
  computed: {
    ...mapGetters({
      stats: 'stats/stats',
    }),
  },
  methods: {
    ...mapActions({
      getStats: 'stats/getStats',
      getFluxItem: 'fluxItems/getFluxItem',
    }),
    async fitToBounds(geom) {
      const bounds = bbox(geom)

      const fitToPromisified = promisify(this.$options.map, 'fitBounds')
      await fitToPromisified(bounds, {
        speed: 3,
        padding: 40,
      })
    },
    moveLayers(map) {
      map.moveLayer('bdOrthoTiles', 'tunnel-major-link-case')
      const layerOrder = ['deptPolygon', 'deptStroke']
      layerOrder.forEach((l) => {
        if (map.getLayer(l)) {
          map.moveLayer(l)
        }
      })
    },
    async onLoad({ map }) {
      this.loading = true
      // store the map as a non reactive object - https://github.com/phegman/vue-mapbox-gl/issues/58
      this.$options.map = map
      if (!this.stats) {
        const params = { active: true }
        await this.getStats({ params })
      }

      this.setLayers(map)

      this.$emit('loaded')
    },
    formatDept(feature) {
      const properties = { ...feature.properties }
      delete properties.code
      let propertiesHTML = `<h3 class="mb-3">Département: ${feature.properties.code}</h3><ul class="properties ma-0 pa-0">`
      propertiesHTML += '</ul>'

      return propertiesHTML
    },
    formatItem(feature) {
      const properties = { ...feature.properties }

      let propertiesHTML = `<h3 class="mb-3">Annonce ${
        feature.properties.isActive ? 'active' : 'inactive'
      }</h3><ul class="properties ma-0 pa-0">`
      propertiesHTML += `<li class="pa-2"><b class="text-capitalize">${properties.title}</b></li>`
      propertiesHTML += `<li class="pa-2"><b class="text-capitalize">Adresse</b> : ${properties.finalAddress}</li>`
      propertiesHTML += `<li class="pa-2"><b class="text-capitalize">Prix</b> : ${properties.price} €</li>`
      propertiesHTML += `<li class="pa-2"><b class="text-capitalize">Superficies</b> : ${properties.area} m² habitables - ${properties.landArea} m² de terrain</li>`
      propertiesHTML += '</ul>'

      return propertiesHTML
    },
    resize() {
      setTimeout(() => {
        this.$options.map.resize()
      }, 100)
    },
    setCursor(map, value) {
      map.getCanvas().style.cursor = value
    },
    setLayers(map) {
      this.totatAds = this.stats.depts.reduce((v, dep) => {
        if (dep.total) {
          v += parseInt(dep.total)
        }

        return v
      }, 0)

      setTimeout(() => {
        const features = Map(
          filter(this.stats.depts, (s) => {
            return s.total
          }),
          (s) => {
            const center = s.geomCenter
            return {
              type: 'Feature',
              geometry: s.geom,
              properties: {
                total: parseInt(s.total),
                code: s.codeDept,
                center,
              },
            }
          }
        )

        map.getSource('dept').setData({
          type: 'FeatureCollection',
          features,
        })

        map.getSource('deptPoint').setData({
          type: 'FeatureCollection',
          features: Map(
            filter(this.stats.depts, (s) => {
              return s.total
            }),
            (s) => {
              const center = s.geomCenter
              return {
                type: 'Feature',
                geometry: center,
                properties: {
                  total: s.total,
                  code: s.codeDept,
                },
              }
            }
          ),
        })
        this.toggleBDOrtho()
        this.loading = false
      }, 100)
    },
    toggleBDOrtho() {
      this.$options.map.setLayoutProperty(
        'bdOrthoTiles',
        'visibility',
        'visible'
      )
      this.moveLayers(this.$options.map)
    },
    onDeptEnter({ map, mapboxEvent }) {
      this.setCursor(map, 'pointer')
      const feature = mapboxEvent.features[mapboxEvent.features.length - 1]
      this.popup
        .setLngLat(feature.geometry.coordinates)
        .setHTML(this.formatDept(mapboxEvent.features[0]))
        .addTo(map)
    },
    onDeptLeave({ map }) {
      this.setCursor(map, '')
      this.popup.remove()
    },
    async onDeptClick({ map, mapboxEvent }) {
      const properties =
        mapboxEvent.features[mapboxEvent.features.length - 1].properties
      const { department } = await clientApi.get(
        `boundaries/departments/${properties.code}`
      )

      const feature = {
        type: 'Feature',
        geometry: department.geom,
      }

      map.getSource('deptBoundary').setData({
        type: 'FeatureCollection',
        features: [mask(feature)],
      })

      this.selectedDept = properties.code
      await this.fitToBounds(department.geom)
      this.$options.map.setLayoutProperty(
        'deptsBoundaries',
        'visibility',
        'none'
      )
      this.$options.map.setLayoutProperty('deptLayer', 'visibility', 'none')

      const items = await clientApi.get(
        `fluxItems?state=validated&codeDept=${properties.code}${
          this.activeOnly ? '&structuredData.isActive=true' : ''
        }`
      )

      let features = Map(
        filter(items, (i) => {
          return i.finalGeom
        }),
        (i) => {
          return {
            type: 'Feature',
            geometry: centroid({
              type: 'Feature',
              geometry: i.finalGeom,
            }).geometry,
            properties: {
              ...{
                fluxItemId: i.id,
                finalAddress: i.finalAddress || null,
                source: i.source,
              },
              ...i.structuredData,
            },
          }
        }
      )

      map.getSource('items').setData({
        type: 'FeatureCollection',
        features,
      })

      features = Map(items, (i) => {
        return {
          type: 'Feature',
          geometry: i.finalGeom,
          properties: {
            fluxItemId: i.id,
            isActive: i.structuredData.isActive,
          },
        }
      })

      this.parcellesFeatures = features

      map.getSource('parcelles').setData({
        type: 'FeatureCollection',
        features,
      })
    },
    onItemEnter({ map, mapboxEvent }) {
      this.setCursor(map, 'pointer')
      const feature = mapboxEvent.features[mapboxEvent.features.length - 1]
      this.popup
        .setLngLat(feature.geometry.coordinates)
        .setHTML(this.formatItem(mapboxEvent.features[0]))
        .addTo(map)
    },
    onItemLeave({ map }) {
      this.setCursor(map, '')
      this.popup.remove()
    },
    async onItemClick({ mapboxEvent }) {
      const item = mapboxEvent.features[mapboxEvent.features.length - 1]
      const features = this.parcellesFeatures
      const parcelle = find(features, (f) => {
        return f.properties.fluxItemId === item.properties.fluxItemId
      })
      const params = { fluxItemId: item.properties.fluxItemId }
      await this.getFluxItem({ params })
      await this.fitToBounds(parcelle.geometry)
    },
    async outsideClick({ map }) {
      this.selectedDept = null
      map.getSource('deptBoundary').setData(this.emptyFeatures)
      map.getSource('items').setData(this.emptyFeatures)
      this.$options.map.setLayoutProperty(
        'deptsBoundaries',
        'visibility',
        'visible'
      )
      this.$options.map.setLayoutProperty('deptLayer', 'visibility', 'visible')
    },
    async toggleActiveOnly(v = null) {
      if (v !== null) {
        this.activeOnly = v
      } else {
        this.activeOnly = !this.activeOnly
      }
      this.loading = true
      const params = { active: this.activeOnly }
      await this.getStats({ params })
      this.setLayers(this.$options.map)
    },
  },
}
</script>
<style lang="scss">
.properties {
  list-style-type: none;
  li:nth-of-type(odd) {
    background: #f2f4f5;
  }
}
.total-ads {
  position: absolute;
  margin: 20px;
  font-size: 1.2em;
}
#map-loader {
  position: absolute;
  z-index: 100;
}
.active-only {
  position: absolute;
  padding-right: 0;
  background: rgba(0, 0, 0, 0.5);
  right: 0;
  bottom: 0;
}
</style>
