import consola from 'consola'

consola.level = process.env.LOG_LEVEL

function log(name, level) {
  return function decorator(t, n, descriptor) {
    const original = descriptor.value
    if (typeof original === 'function') {
      descriptor.value = async function (...args) {
        if (level === 3) {
          consola.info(`${name}...`)
        } else if (level === 4) {
          consola.debug(`${name}...`)
        }
        consola.debug(args)
        try {
          const result = await original.apply(this, args)
          consola.success(`${name} : OK`)
          return result
        } catch (e) {
          consola.error(e)
          consola.error(`${name} : KO`)
          throw e
        }
      }
    }
    return descriptor
  }
}

function logSync(name, level) {
  return function decorator(t, n, descriptor) {
    const original = descriptor.value
    if (typeof original === 'function') {
      descriptor.value = function (...args) {
        if (level === 3) {
          consola.info(`${name}...`)
        } else if (level === 4) {
          consola.debug(`${name}...`)
        }
        try {
          const result = original.apply(this, args)
          consola.success(`${name} : OK`)
          return result
        } catch (e) {
          consola.error(e)
          consola.error(`${name} : KO`)
          throw e
        }
      }
    }
    return descriptor
  }
}

export { log, logSync }
