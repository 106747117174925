<template>
  <v-row class="pa-0" no-gutters>
    <v-col
      v-for="(s, i) in simPics"
      :key="i"
      cols="12"
      class="property px-5 py-3"
    >
    <v-row no-gutters>
        <v-col cols="1"
          ><b>photos</b></v-col
        >
        <v-col offset="2" cols="3">
          <v-img
          :src="photosA[s.leftIdx]"
          aspect-ratio="1"
          contain
          class="grey lighten-2"
          @click="openGallery(s.leftIdx, photosA)"
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
        </v-col>
        <v-col cols="1" class="text-center">
          {{s.score.toFixed(2)}}
        </v-col>
        <v-col cols="3">
          <v-img
          :src="photosB[s.rightIdx]"
          aspect-ratio="1"
          contain
          class="grey lighten-2"
          @click="openGallery(s.rightIdx, photosB)"
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
        </v-col>
      </v-row>
    </v-col>
    <v-col
      v-for="p in toCompare"
      :key="p.key"
      cols="12"
      class="property px-5 py-3"
    >
      <v-row no-gutters>
        <v-col cols="1"
          ><b>{{ p.label }}</b></v-col
        >
        <v-col cols="5"><div class="a" v-html="p.format(fluxItemA)" /></v-col>
        <v-col cols="1" class="text-center">
          <v-icon
            v-if="p.compare(fluxItemA, fluxItemB)"
            class="ma-auto"
            color="green"
            >mdi-checkbox-marked-circle</v-icon
          >
          <v-icon v-else class="ma-auto" color="orange"
            >mdi-alert-circle</v-icon
          >
        </v-col>
        <v-col cols="5"><div v-html="p.format(fluxItemB)" /></v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import { mapActions } from 'vuex'
import { format, parseISO } from 'date-fns'
import { TSCommonMixin } from '@/mixins/TSCommonMixin'

export default {
  name: 'TSAdDiffWidget',
  props: {
    fluxItemA: {
      type: Object,
      required: true,
    },
    fluxItemB: {
      type: Object,
      required: true,
    },
    photosA: {
      type: Array,
      default: () => []
    },
    photosB: {
      type: Array,
      default: () => []  
    },
    similarities: {
      type: Array,
      default: () => []
    }
  },
  mixins: [TSCommonMixin],
  data() {
    return {
      simPics: [],
      toCompare: [
        {
          key: 'source',
          label: 'Source',
          format: (a) => {
            return `${a.source} - <a href="${a.structuredData.sourceUrl}" target="blank" rel="noreferrer">${a.sourceId}</a>`
          },
          compare: (a, b) => {
            return a.structuredData.sourceUrl === b.structuredData.sourceUrl
          },
        },
        {
          key: 'internalRef',
          label: 'Référence',
          format: (a) => {
            return a.structuredData.internalRef
          },
          compare: (a, b) => {
            return a.structuredData.internalRef === b.structuredData.internalRef
          },
        },
        {
          key: 'finalAddress',
          label: 'Adresse',
          format: (a) => {
            return a.finalAddress
          },
          compare: (a, b) => {
            return a.finalAddress === b.finalAddress
          },
        },
        {
          key: 'state',
          label: 'État',
          format: (a) => {
            return a.state
          },
          compare: (a, b) => {
            return a.state === b.state
          },
        },
        {
          key: 'date',
          label: 'Annonce insérée le',
          format: (a) => {
            if (a.createdAt) {
              return format(parseISO(a.createdAt), 'dd/MM/yyyy')
            } else {
              return 'N.C'
            }
          },
          compare: (a, b) => {
            return a.createdAt === b.createdAt
          },
        },
        {
          key: 'price',
          label: 'Prix',
          format: (a) => {
            return `${a.structuredData.price} €`
          },
          compare: (a, b) => {
            return (
              Number(a.structuredData.price) === Number(b.structuredData.price)
            )
          },
        },
        {
          key: 'area',
          label: 'Surface habitable',
          format: (a) => {
            return `${a.structuredData.area} m²`
          },
          compare: (a, b) => {
            return (
              Number(a.structuredData.area) === Number(b.structuredData.area)
            )
          },
        },
        {
          key: 'landArea',
          label: 'Surface terrain',
          format: (a) => {
            return `${a.structuredData.landArea} m²`
          },
          compare: (a, b) => {
            return (
              Number(a.structuredData.landArea) ===
              Number(b.structuredData.landArea)
            )
          },
        },
        {
          key: 'description',
          label: 'Description',
          format: (a) => {
            return a.structuredData.description
          },
          compare: (a, b) => {
            return a.structuredData.description === b.structuredData.description
          },
        },
      ],
    }
  },
  computed: {},
  methods: {
    ...mapActions({}),
    openGallery(i, photos) {
      this.$root.$emit('toggleGallery', {
        open: true,
        currentIndex: i,
        photos
      })
    },
  },
  watch: {
    fluxItemA() {
      this.simPics = []
    },
    fluxItemB() {
      this.simPics = []
    },
    similarities: {
      immediate: true,
      handler: function (newSimilarities, oldSimilarities) {
        if (oldSimilarities !== newSimilarities) {
          const max = []
          newSimilarities.forEach((scores, i) => {
            const maxSc = Math.max(...scores)
            if (maxSc > 0.85) {
              max.push({score: maxSc, leftIdx:i, rightIdx :scores.findIndex(v => (v === maxSc)) })
            }
          });

          max.sort((a, b) => (b.score - a.score))
          this.simPics = max
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.property {
  font-size: 12px;
  &:nth-of-type(odd) {
    background: #f2f4f5;
  }
}

.subtitle-1 {
  font-size: 12px !important;
}

.a {
  text-align: right;
}
</style>
<style lang="scss"></style>
