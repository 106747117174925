import { log, logSync } from '@/decorators'
import { findIndex } from 'lodash-es'
import matchApi from '@/api/matchApi'
// initial state
const state = () => ({
  currentMatch: null,
  selectedMatches: [],
  streetViewData: null,
  currentProof: {
    compareWithStreetView: false,
    compareWithSatelliteView: false,
    photos: [],
    streetViewData: null,
    satelliteMarkers: [],
    satelliteZoom: null,
    comment: '',
    useBDOrtho: false,
    trust: 1,
  },
  proofs: [],
})

// getters
const getters = {
  selectedMatches: (state) => {
    return state.selectedMatches
  },
  currentMatch: (state) => {
    return state.currentMatch
  },
  streetViewData: (state) => {
    return state.streetViewData
  },
  currentProof: (state) => {
    return state.currentProof
  },
  proofs: (state) => {
    return state.proofs
  },
}

/* eslint-disable */
// actions
const actions = {
  @log('STORE - ACTION - MATCHS - CREATE MATCH', 3)
  async createMatch({ commit, state }, params) {
    const res = await matchApi.createMatch(params)
    // commit('ADD_NEW_MATCH', res.match)
    return res.match
  },
  @log('STORE - ACTION - MATCHS - UPDATE MATCH', 3)
  async updateMatch({ commit, state }, params) {
    const match = await matchApi.updateMatch(params)
    commit('fluxItems/UPDATE_MATCH', match, { root: true})
    return match
  }
}
// mutations
const mutations = {
  @logSync('STORE - MUTATION - MATCHS - SET CURRENT MATCH', 4)
  SET_CURRENT_MATCH(state, match) {
    state.currentMatch = match
  },
  @logSync('STORE - MUTATION - MATCHS - SET SELECTED MATCHES', 4)
  SET_SELECTED_MATCHES(state, matches) {
    state.selectedMatches = matches
  },
  @logSync('STORE - MUTATION - MATCHS - SET CURRENT PROOF', 4)
  SET_CURRENT_PROOF(state, proof){
    state.currentProof = proof
  },
  @logSync('STORE - MUTATION - MATCHS - SET PROOFS', 4)
  SET_PROOFS(state, proofs){
    state.proofs = proofs
  },
  @logSync('STORE - MUTATION - MATCHS - SET PROOF PROPERTY', 4)
  SET_PROOF_PROPERTY(state, { key, value }) {
    state.currentProof[key] = value
  },
  @logSync('STORE - MUTATION - MATCHS - REMOVE PROOF', 4)
  REMOVE_PROOF(state, idx) {
   state.proofs.splice(idx, 1)
  },
  @logSync('STORE - MUTATION - MATCHS - RESET PROOFS', 4)
  RESET_PROOFS(state) {
    state.proofs = []
  },
  @logSync('STORE - MUTATION - MATCHS - RESET PROOF', 4)
  RESET_PROOF(state) {
    state.currentProof = {
      compareWithStreetView: false,
      compareWithSatelliteView: false,
      photos: [],
      streetViewData: null,
      satelliteMarkers: [],
      satelliteZoom: null,
      comment: '',
      useBDOrtho: false,
      trust: 1
    }
  },
  @logSync('STORE - MUTATION - MATCHS - ADD PROOFS', 4)
  ADD_PROOF(state) {
    state.proofs.push({...state.currentProof, streetViewData: state.streetViewData })
    state.currentProof = {
      compareWithStreetView: false,
      compareWithSatelliteView: false,
      photos: [],
      streetViewData: null,
      satelliteMarkers: [],
      satelliteZoom: null,
      comment: '',
      useBDOrtho: false,
      trust: 1
    }
  },
  @logSync('STORE - MUTATION - MATCHS - REMOVE FROM SELECTED MATCHES', 4)
  REMOVE_FROM_SELECTED_MATCHES(state, match) {
    const index = findIndex(state.selectedMatches, m => { return m.id === match.id })
    if (index !== -1) {
      state.selectedMatches.splice(index, 1)
    }
  },
  SET_STREET_VIEW_DATA(state, data) {
    state.streetViewData = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
