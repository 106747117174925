<template>
  <div>
    <v-row class="fill-height" v-if="loading">
      <v-col cols="12" class="d-flex">
        <v-progress-linear indeterminate color="white" />
      </v-col>
    </v-row>
    <div v-else>
      <v-col cols="12" class="mt-5 text-h4 font-weight-light d-flex">
        <div class="white--text">
          <span class="f-title">{{ currentTeam.name }}</span>
          <br />
          <small class="ml-2 mt-4 f-desc">{{ currentTeam.description }}</small>
        </div>
        <v-spacer />
      </v-col>
      <draggable
        :list="widgets"
        handle=".handle"
        group="widgets"
        class="row px-3"
      >
        <t-s-widget
          v-for="w in widgets"
          v-bind="w"
          :user="currentUser"
          :teamItem="{}"
          :key="w.id"
          @refresh="onRefresh"
          @loaded="onLoaded"
        />
      </draggable>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TSCommonMixin } from '@/mixins/TSCommonMixin'
import draggable from 'vuedraggable'
import TSWidget from '@/components/team/widgets/TSWidget'
export default {
  name: 'TeamWork',
  components: {
    draggable,
    TSWidget,
  },
  mixins: [TSCommonMixin],
  data: () => ({
    loading: true,
    isMapboxReady: false,
    isStreetViewReady: false,
    widgets: [
      {
        id: 'operatorChart',
        title: 'Suivi Opérateurs',
        type: 'operatorChart',
        cols: 12,
        isExpandable: true,
      },
    ],
  }),
  created() {
    this.load(this.$route.params.id)
  },
  destroyed() {
    this.setCurrentTeam(null)
  },
  watch: {
    $route(to) {
      this.load(to.params.id)
    },
  },
  computed: {
    ...mapGetters({
      currentTeam: 'teams/currentTeam',
    }),
  },
  methods: {
    ...mapActions({
      getTeamDetails: 'teams/getTeamDetails',
    }),
    ...mapMutations({
      setCurrentTeam: 'teams/SET_CURRENT_TEAM',
    }),
    async load(teamId) {
      this.msg = ''
      this.loading = true
      await this.getTeamDetails({ id: teamId })
      this.loading = false
    },
    onLoaded(type) {
      console.log(type)
    },
    onRefresh(type) {
      console.log(type)
    },
  },
}
</script>

<style lang="scss" scoped>
.f-title {
  font-size: 1.5rem !important;
  display: inline-block;
  vertical-align: middle;
}
.f-desc {
  display: inline-block;
  line-height: 1.3em;
  max-width: 500px;
  font-size: 16px;
}
</style>
