<template>
  <v-dialog :value="open" max-width="500px" @click:outside="close">
    <v-card class="pa-2">
      <v-card-title>
        <span class="headline font-weight-light">
          {{ title }}
        </span>
      </v-card-title>
      <v-card-text>
        <v-form>
          <v-container class="fill-height">
            <v-row justify="center" align="center">
              <v-col cols="12">
                <v-text-field label="Nom" v-model="dflux.name" hide-details />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Description"
                  v-model="dflux.description"
                  hide-details
                />
              </v-col>
              <v-col cols="12">
                <v-checkbox
                  v-model="dflux.active"
                  label="Actif"
                  color="primary"
                  hide-details
                />
              </v-col>
              <v-col cols="12">
                <small>
                  Pour configurer le flux, cliquez sur le bouton
                  <v-icon small color="primary">mdi-cog</v-icon> dans la colonne
                  <b>Actions</b>.
                </small>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="primary" class="text-none" @click="close">
          Annuler
        </v-btn>
        <v-btn color="secondary" class="text-none" @click="save">
          Sauvegarder
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'TSFluxDialogCreateUpdate',
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    flux: {
      type: Object,
      default: () => ({
        customData: {},
      }),
    },
  },
  data() {
    return {
      dflux: { settings: {} },
    }
  },
  computed: {},
  watch: {
    flux(val) {
      this.dflux = val
    },
  },
  methods: {
    close() {
      this.$emit('close', {})
    },
    save() {
      this.$emit('save', this.dflux)
    },
  },
}
</script>

<style lang="scss" scoped></style>
