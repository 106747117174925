<template>
  <v-snackbar
    v-model="snackbar.open"
    :timeout="snackbar.timeout"
    :color="snackbar.color"
    :bottom="true"
  >
    {{ snackbar.message }}
    <template v-slot:action="{ attrs }">
      <v-btn dark text v-bind="attrs" @click.native="snackbar.open = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>
<script>
export default {
  name: 'TSnackBar',
  data() {
    return {
      snackbar: {
        timeout: 2000,
        color: 'error',
        text: '',
        open: false,
      },
    }
  },
  mounted() {
    this.$root.$on('openSnackBar', this.openSnackBar)
  },
  destroyed() {
    this.$root.$off('openSnackBar')
  },
  methods: {
    openSnackBar(value) {
      this.snackbar.message = value.message
      this.snackbar.color = value.color
      this.snackbar.open = true
    },
  },
}
</script>
