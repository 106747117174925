const $axios = require('axios')
import { log } from '@/decorators'
/* eslint-disable */
export default {
  endPoint: [
    `/${process.env.VUE_APP_API_VERSION}/users`, 
    `/${process.env.VUE_APP_API_VERSION}/users/:userId/flux`
  ],
  client: $axios.create({
    withCredentials: true,
    baseURL: process.env.VUE_APP_API_URL,
    timeout: process.env.VUE_APP_API_TIMEOUT
  }),
  @log('USERS - API - GET USERS', 3)
  async getUsers(params) {
    const res = await this.client.get(`${this.endPoint[0]}`, params)
    return res.data
  },
  @log('USERS - API - CREATE USER', 3)
  async createUser(params) {
    const res = await this.client.post(`${this.endPoint[0]}`, { user: params.user })
    return res.data
  },
  @log('USERS - API - UPDATE USER', 3)
  async updateUser(params) {
    const res = await this.client.patch(`${this.endPoint[0]}/${params.user.id}`, params.user)
    return res.data
  },
  @log('USERS - API - DELETE USER', 3)
  async deleteUser(params) {
    const res = await this.client.delete(`${this.endPoint[0]}/${params.id}`)
    return res
  }
}
