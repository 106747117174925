import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import fr from 'vuetify/es5/locale/fr'

Vue.use(Vuetify)

const opts = {
  theme: {
    themes: {
      light: {
        primary: '#14617E',
        secondary: '#34c2b8',
        accent: '#83F2BE',
      },
    },
  },
  lang: {
    locales: { fr },
    current: 'fr',
  },
}

export default new Vuetify(opts)
