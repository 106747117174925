<template>
  <v-dialog
    v-model="open"
    content-class="fit"
    max-width="1280"
    :max-height="maxHeight"
  >
    <v-card class="gradient fit">
      <div class="picture-index accent elevation-2">{{ currentIndex + 1 }}</div>
      <v-img :src="currentSrc" contain :max-height="maxHeight" />
      <v-btn v-if="photos.length > 1" class="btn prev accent" @click="prev">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-btn v-if="photos.length > 1" class="btn next accent" @click="next">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'TSPhotoGallery',
  data() {
    return {
      currentIndex: 0,
      maxHeight: 800,
      open: false,
      photos: [],
    }
  },
  mounted() {
    this.$root.$on('toggleGallery', this.toggleGallery)
    this.computeMaxHeight()
    window.addEventListener('resize', this.computeMaxHeight, false)
    document.addEventListener('keyup', this.keyListener)
  },
  destroyed() {
    this.$root.$off('toggleGallery', this.toggleGallery)
    window.removeEventListener('resize', this.computeMaxHeight)
    document.removeEventListener('keyup', this.keyListener)
  },
  computed: {
    currentSrc() {
      return this.photos && this.currentIndex < this.photos.length
        ? this.photos[this.currentIndex]
        : ''
    },
  },
  methods: {
    computeMaxHeight() {
      const h =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight
      this.maxHeight = h * 0.8
    },
    toggleGallery({ open, currentIndex, photos }) {
      this.currentIndex = currentIndex
      this.photos = photos
      this.open = open
    },
    keyListener(evt) {
      switch (evt.keyCode) {
        case 37:
          this.prev()
          break
        case 39:
          this.next()
          break
        case 27:
          this.open = false
          break
        default:
          break
      }
    },
    next() {
      const next = this.currentIndex + 1
      this.currentIndex = next < this.photos.length ? next : 0
    },
    prev() {
      const prev = this.currentIndex - 1
      this.currentIndex = prev < 0 ? this.photos.length - 1 : prev
    },
  },
}
</script>
<style lang="scss">
.fit {
  width: fit-content !important;
  overflow: hidden;
  .btn {
    position: absolute;
    opacity: 0.7;
    height: 100px !important;
    top: calc(50% - 50px);
    &.next {
      right: -10px;
      border-top-left-radius: 60px;
      border-bottom-left-radius: 60px;
    }
    &.prev {
      left: -10px;
      border-top-right-radius: 60px;
      border-bottom-right-radius: 60px;
    }
  }
}

.picture-index {
  line-height: 40px;
  text-align: center;
  height: 40px;
  width: 40px;
  font-size: 20px;
  z-index: 10;
  position: absolute;
  color: white;
  border-top-right-radius: 0 !important;
  text-shadow: 1px 1px 1px rgba($color: #000000, $alpha: 0.5);
  opacity: 0.7;
}
</style>
