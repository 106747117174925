const $axios = require('axios')
import { log } from '@/decorators'
/* eslint-disable */
export default {
  endPoint: [
    `/${process.env.VUE_APP_API_VERSION}/fluxItems`,
    `/${process.env.VUE_APP_API_VERSION}/fluxItems/:id/matches`,
    `/${process.env.VUE_APP_API_VERSION}/fluxItems/:id/doublons`,
    `/${process.env.VUE_APP_API_VERSION}/flux/:id/fluxItems`,
    `/${process.env.VUE_APP_API_VERSION}/flux/:id/fluxItem`,
    `/${process.env.VUE_APP_API_VERSION}/fluxItems/:id`,
  ],
  client: $axios.create({
    withCredentials: true,
    baseURL: process.env.VUE_APP_API_URL,
    timeout: process.env.VUE_APP_API_TIMEOUT
  }),
  @log('FLUX ITEMS - API - GET FLUX ITEMS', 3)
  async getFluxItems(params) {
    const res = await this.client.get(`${this.endPoint[3].replace(':id', params.params.fluxId)}`, params)
    return res.data
  },
  @log('FLUX ITEMS - API - GET DOUBLONS', 3)
  async getDoublons(params) {
    const res = await this.client.get(`${this.endPoint[2].replace(':id', params.id)}`, { params })
    return res.data.fluxItems
  },
  @log('FLUX ITEMS - API - UPDLATE DOUBLONS', 3)
  async updateDoublons(params) {
    const res = await this.client.post(`${this.endPoint[2].replace(':id', params.id)}`, { params })
    return res.data.fluxItems
  },
  @log('FLUX ITEMS - API - GET FLUX ITEM', 3)
  async getFluxItem(params) {
    let res

    if (params.params.fluxId) {
      res = await this.client.get(`${this.endPoint[4].replace(':id', params.params.fluxId)}`, params)
    } else {
      res = await this.client.get(`${this.endPoint[5].replace(':id', params.params.fluxItemId)}`, params)
    }
    return res.data.fluxItem
  },
  @log('FLUX ITEMS - API - GET FLUX ITEM MATCHES', 3)
  async getFluxItemMatches(params) {
    const res = await this.client.get(`${this.endPoint[1].replace(':id', params.id)}`,  { params })
    return res.data.matches
  },
  @log('FLUX ITEMS - API - UPDATE FLUX ITEM', 3)
  async updateFluxItem(params) {
    const res = await this.client.patch(`${this.endPoint[0]}/${params.fluxItem.id}`, params.fluxItem)
    return res.data
  }
}