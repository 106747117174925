<template>
  <div class="ts-users-table-container mt-6">
    <v-data-table
      :headers="headers"
      :items="users"
      :search="search"
      :options.sync="options"
      :server-items-length="totalUsers"
      :loading="loading"
      class="elevation-1"
      :footer-props="{
        itemsPerPageOptions: [5, 10, 20, 50, 100],
      }"
    >
      <template v-slot:top>
        <v-row class="pa-5">
          <v-spacer />
          <v-col cols="6">
            <v-text-field
              v-model="options.search"
              append-icon="mdi-magnify"
              single-line
              solo
              dense
              flat
              hide-details
              class="elevation-1 grey--text"
            >
              <template v-slot:label>
                <small>Rechercher (email, rôle, nom, prénom)</small>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.customData.role`]="{ item }">
        <v-chip :color="getRole(item.customData.role).color" small label dark>
          <v-icon small left>
            {{ getRole(item.customData.role).icon }}
          </v-icon>
          {{ item.customData.role }}
        </v-chip>
      </template>
      <template v-slot:[`item.createdAt`]="{ item }">
        {{ formatDate(item.createdAt) }}
      </template>
      <template v-slot:[`item.updatedAt`]="{ item }">
        {{ formatDate(item.updatedAt) }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn
              @click="editUser(item)"
              color="primary"
              dark
              icon
              v-on="tooltip"
            >
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Modifier l'utilisateur</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn @click="deleteUser(item)" icon v-on="tooltip">
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </template>
          <span>Supprimer l'utilisateur</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { format, parseISO } from 'date-fns'
import { cloneDeep } from 'lodash-es'
export default {
  name: 'TSUsersTable',
  props: {
    users: {
      type: Array,
      default: () => [],
    },
    totalUsers: {
      type: Number,
      default: 0,
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      headers: [
        {
          text: 'ID',
          align: 'start',
          sortable: true,
          value: 'id',
        },
        { text: 'Email', value: 'email' },
        { text: 'Rôle', value: 'customData.role' },
        { text: 'Téléphone', value: 'customData.phone' },
        { text: 'Nom', value: 'customData.lastname' },
        { text: 'Prénom', value: 'customData.firstname' },
        { text: 'Crée le', value: 'createdAt' },
        { text: 'Modifié le', value: 'updatedAt' },
        { text: 'Actions', align: 'center', value: 'actions', sortable: false },
      ],
      options: {
        search: '',
        itemsPerPage: 10,
      },
      search: '',
    }
  },
  watch: {
    options: {
      handler() {
        this.$emit('optionsChange', this.options)
      },
      deep: true,
    },
  },
  methods: {
    getRole(role) {
      switch (role) {
        case 'ADMIN':
          return { color: 'red', icon: 'mdi-shield-check' }
        case 'VALIDATOR':
          return { color: 'primary', icon: 'mdi-account-check' }
        case 'OPERATOR':
          return { color: 'teal', icon: 'mdi-account-search' }
        default:
          break
      }
    },
    deleteUser(user) {
      this.$emit('delete', user.id)
    },
    editUser(user) {
      this.$emit('edit', cloneDeep(user))
    },
    formatDate(date) {
      return format(parseISO(date), 'dd/MM/yyyy')
    },
  },
}
</script>

<style lang="scss" scoped>
.ts-users-table-container {
  width: 100%;
}
</style>
