import { log, logSync } from '@/decorators'
import authApi from '@/api/authApi'

// initial state
const state = () => ({
  currentUser: null,
})

// getters
const getters = {
  currentUser: (state) => {
    return state.currentUser
  },
}
/* eslint-disable */
// actions
const actions = {
  @log('STORE - ACTION - AUTH - CREATE', 3)
  async create({ commit }, { email, password }) {
    const res =  await authApi.create({ email, password })
    commit('SET_CURRENT_USER', res.user)
  },
    @log('STORE - ACTION - AUTH - DELETE', 3)
  async delete({ commit }) {
    await authApi.delete()
    commit('SET_CURRENT_USER', null)
  },
  async authMe({ commit }) {
    const res =  await authApi.authMe()
    commit('SET_CURRENT_USER', res.user)
  }
}

// mutations
const mutations = {
  @logSync('STORE - MUTATION - AUTH - SET CURRENT USER', 3)
  SET_CURRENT_USER(state, user) {
    state.currentUser = user
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
