<template>
  <div>
    <v-row class="pa-6">
      <v-col cols="12" class="mt-5 text-h4 font-weight-light d-flex">
        <div class="white--text">Utilisateurs</div>
        <v-spacer />
        <v-btn color="secondary" class="text-none" @click="onCreateUser">
          <v-icon class="mr-2">mdi-account-plus</v-icon>
          Créer un utilisateur
        </v-btn>
      </v-col>
      <v-col cols="12" class="d-flex">
        <TSUsersTable
          :users="users"
          :loading="loading"
          :totalUsers="totalUsers"
          @optionsChange="onOptionsChange"
          @edit="onEditUser"
          @delete="onDeleteUser"
        />
      </v-col>
    </v-row>
    <TSUserDialogCreateUpdate
      :user="userDialogCreateUpdateUser"
      :open="userDialogCreateUpdateOpen"
      :title="userDialogCreateUpdateTitle"
      @close="userDialogCreateUpdateOnClose"
      @save="userDialogCreateUpdateOnSave"
    />
    <v-dialog
      :value="userDialogDeleteOpen"
      max-width="800px"
      @click:outside="userDialogDeleteOpen = false"
    >
      <v-card class="pa-2">
        <v-card-title class="headline font-weight-light">
          Êtes-vous sûr de vouloir supprimer cet utilisateur ?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            class="text-none"
            @click="userDialogDeleteOpen = false"
          >
            Annuler
          </v-btn>
          <v-btn
            dark
            color="red"
            class="text-none"
            @click="onDeleteUserConfirm"
          >
            <v-icon class="mr-3">mdi-alert</v-icon>
            Oui, supprimer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import TSUsersTable from '@/components/user/TSUsersTable'
import TSUserDialogCreateUpdate from '@/components/user/TSUserDialogCreateUpdate'

export default {
  name: 'Users',
  components: { TSUsersTable, TSUserDialogCreateUpdate },
  data() {
    return {
      loading: true,
      userDialogCreateUpdateOpen: false,
      userDialogCreateUpdateTitle: '',
      userDialogCreateUpdateUser: null,
      userDialogDeleteOpen: false,
      userDialogDeleteId: null,
    }
  },
  mounted() {},
  methods: {
    ...mapActions({
      createUser: 'users/createUser',
      deleteUser: 'users/deleteUser',
      getUsers: 'users/getUsers',
      updateUser: 'users/updateUser',
    }),
    onCreateUser() {
      this.userDialogCreateUpdateTitle = ' Créer un utilisateur'
      this.userDialogCreateUpdateUser = {
        customData: {},
      }
      this.userDialogCreateUpdateOpen = true
    },
    async onDeleteUserConfirm() {
      if (this.userDialogDeleteId) {
        this.loading = true
        const r = await this.deleteUser({ id: this.userDialogDeleteId }).catch(
          (e) => {
            this.$root.$emit('openSnackBar', {
              message: `L'utilisateur n'a pas été supprimé. ${e}`,
              color: 'error',
            })
            this.loading = false
          }
        )

        if (r) {
          this.$root.$emit('openSnackBar', {
            message: "L'utilisateur a bien été supprimé.",
            color: 'success',
          })
          this.userDialogDeleteId = null
          this.userDialogDeleteOpen = false
        }
        this.loading = false
      }
    },
    async onOptionsChange(options) {
      const params = {
        page: options.page - 1,
        size: options.itemsPerPage,
      }
      if (options.sortBy.length) {
        params.order = []
        options.sortBy.forEach((e, i) => {
          params.order.push([e, options.sortDesc[i] ? 'DESC' : 'ASC'])
        })
      } else {
        params.order = [['id', 'DESC']]
      }
      if (options.search && options.search !== '') {
        params.search = options.search
      }

      await this.getUsers({ params })
      this.loading = false
    },
    onDeleteUser(id) {
      this.userDialogDeleteId = id
      this.userDialogDeleteOpen = true
    },
    onEditUser(user) {
      this.userDialogCreateUpdateTitle = " Modifier l'utilisateur"
      this.userDialogCreateUpdateUser = user
      this.userDialogCreateUpdateOpen = true
    },
    userDialogCreateUpdateOnClose() {
      this.userDialogCreateUpdateOpen = false
    },
    async userDialogCreateUpdateOnSave(user) {
      // edit mode
      if (user.id) {
        this.loading = true
        const r = await this.updateUser({ user }).catch((e) => {
          this.$root.$emit('openSnackBar', {
            message: `L'utilisateur n'a pas été mis à jour. ${e}`,
            color: 'error',
          })
          this.loading = false
        })

        if (r) {
          this.$root.$emit('openSnackBar', {
            message: "L'utilisateur a bien été mis à jour.",
            color: 'success',
          })

          this.userDialogCreateUpdateOpen = false
        }
        this.loading = false
      } else {
        // create mode
        const r = await this.createUser({ user }).catch((e) => {
          this.$root.$emit('openSnackBar', {
            message: `L'utilisateur n'a pas été créé. ${e}`,
            color: 'error',
          })
          this.loading = false
        })

        if (r) {
          this.$root.$emit('openSnackBar', {
            message: "L'utilisateur a bien été créé.",
            color: 'success',
          })

          this.userDialogCreateUpdateOpen = false
        }
        this.loading = false
      }
    },
  },
  computed: {
    ...mapGetters({
      users: 'users/users',
      totalUsers: 'users/totalUsers',
    }),
  },
}
</script>

<style lang="scss" scoped></style>
