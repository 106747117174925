<template>
  <div>
    <v-row class="pa-6">
      <v-col cols="12" class="mt-5 text-h4 font-weight-light d-flex">
        <div class="white--text">Flux</div>
        <v-spacer />
        <v-btn color="secondary" class="text-none" @click="onCreateFlux">
          <v-icon class="mr-2">mdi-account-network</v-icon>
          Créer un flux
        </v-btn>
      </v-col>
      <v-col cols="12" class="d-flex">
        <TSFluxTable
          :flux="flux"
          :loading="loading"
          :totalFlux="totalFlux"
          @optionsChange="onOptionsChange"
          @edit="onEditFlux"
          @delete="onDeleteFlux"
        />
      </v-col>
    </v-row>
    <TSFluxDialogCreateUpdate
      :flux="fluxDialogCreateUpdateFlux"
      :open="fluxDialogCreateUpdateOpen"
      :title="fluxDialogCreateUpdateTitle"
      @close="fluxDialogCreateUpdateOnClose"
      @save="fluxDialogCreateUpdateOnSave"
    />
    <v-dialog
      :value="fluxDialogDeleteOpen"
      max-width="800px"
      @click:outside="fluxDialogDeleteOpen = false"
    >
      <v-card class="pa-2">
        <v-card-title class="headline font-weight-light">
          Êtes-vous sûr de vouloir supprimer ce flux ?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            class="text-none"
            @click="fluxDialogDeleteOpen = false"
          >
            Annuler
          </v-btn>
          <v-btn
            dark
            color="red"
            class="text-none"
            @click="onDeleteFluxConfirm"
          >
            <v-icon class="mr-3">mdi-alert</v-icon>
            Oui, supprimer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import TSFluxTable from '@/components/flux/TSFluxTable'
import TSFluxDialogCreateUpdate from '@/components/flux/TSFluxDialogCreateUpdate'

export default {
  name: 'Flux',
  components: { TSFluxTable, TSFluxDialogCreateUpdate },
  data() {
    return {
      loading: true,
      fluxDialogCreateUpdateOpen: false,
      fluxDialogCreateUpdateTitle: '',
      fluxDialogCreateUpdateFlux: null,
      fluxDialogDeleteOpen: false,
      fluxDialogDeleteId: null,
    }
  },
  mounted() {},
  methods: {
    ...mapActions({
      createFlux: 'flux/createFlux',
      deleteFlux: 'flux/deleteFlux',
      getFlux: 'flux/getFlux',
      updateFlux: 'flux/updateFlux',
    }),
    onCreateFlux() {
      this.fluxDialogCreateUpdateTitle = ' Créer un flux'
      this.fluxDialogCreateUpdateFlux = {
        customData: {},
      }
      this.fluxDialogCreateUpdateOpen = true
    },
    async onDeleteFluxConfirm() {
      if (this.fluxDialogDeleteId) {
        this.loading = true
        const r = await this.deleteFlux({ id: this.fluxDialogDeleteId }).catch(
          (e) => {
            this.$root.$emit('openSnackBar', {
              message: `Le flux n'a pas été supprimé. ${e}`,
              color: 'error',
            })
            this.loading = false
          }
        )

        if (r) {
          this.$root.$emit('openSnackBar', {
            message: 'Le flux a bien été supprimé.',
            color: 'success',
          })
          this.fluxDialogDeleteId = null
          this.fluxDialogDeleteOpen = false
        }
        this.loading = false
      }
    },
    async onOptionsChange(options) {
      const params = {
        page: options.page - 1,
        size: options.itemsPerPage,
      }
      if (options.sortBy.length) {
        params.order = []
        options.sortBy.forEach((e, i) => {
          params.order.push([e, options.sortDesc[i] ? 'DESC' : 'ASC'])
        })
      } else {
        params.order = [['id', 'DESC']]
      }
      if (options.search && options.search !== '') {
        params.search = options.search
      }

      await this.getFlux({ params })
      this.loading = false
    },
    onDeleteFlux(id) {
      this.fluxDialogDeleteId = id
      this.fluxDialogDeleteOpen = true
    },
    onEditFlux(flux) {
      this.fluxDialogCreateUpdateTitle = ' Modifier le flux'
      this.fluxDialogCreateUpdateFlux = flux
      this.fluxDialogCreateUpdateOpen = true
    },
    fluxDialogCreateUpdateOnClose() {
      this.fluxDialogCreateUpdateOpen = false
    },
    async fluxDialogCreateUpdateOnSave(flux) {
      // edit mode
      if (flux.id) {
        this.loading = true
        const r = await this.updateFlux({ flux }).catch((e) => {
          this.$root.$emit('openSnackBar', {
            message: `Le flux n'a pas été mis à jour. ${e}`,
            color: 'error',
          })
          this.loading = false
        })

        if (r) {
          this.$root.$emit('openSnackBar', {
            message: 'Le flux a bien été mis à jour.',
            color: 'success',
          })

          this.fluxDialogCreateUpdateOpen = false
        }
        this.loading = false
      } else {
        // create mode
        const r = await this.createFlux({ flux }).catch((e) => {
          this.$root.$emit('openSnackBar', {
            message: `Le flux n'a pas été créé. ${e}`,
            color: 'error',
          })
          this.loading = false
        })

        if (r) {
          this.$root.$emit('openSnackBar', {
            message: 'Le flux a bien été créé.',
            color: 'success',
          })

          this.fluxDialogCreateUpdateOpen = false
        }
        this.loading = false
      }
    },
  },
  computed: {
    ...mapGetters({
      flux: 'flux/flux',
      totalFlux: 'flux/totalFlux',
    }),
  },
}
</script>

<style lang="scss" scoped></style>
