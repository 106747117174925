const $axios = require('axios')
import { log } from '@/decorators'
/* eslint-disable */
export default {
  endPoint: [`/${process.env.VUE_APP_API_VERSION}/flux`],
  client: $axios.create({
    withCredentials: true,
    baseURL: process.env.VUE_APP_API_URL,
    timeout: process.env.VUE_APP_API_TIMEOUT
  }),
  @log('FLUX - API - GET FLUX', 3)
  async getFlux(params) {
    const res = await this.client.get(`${this.endPoint[0]}`, params)
    return res.data
  },
  @log('FLUX - API - GET FLUX DETAILS', 3)
  async getFluxDetails(params) {
    const res = await this.client.get(`${this.endPoint[0]}/${params.id}`)
    return res.data
  },
  @log('FLUX - API - CREATE FLUX', 3)
  async createFlux(params) {
    const res = await this.client.post(`${this.endPoint[0]}`, { flux: params.flux })
    return res.data
  },
  @log('FLUX - API - UPDATE FLUX', 3)
  async updateFlux(params) {
    const res = await this.client.patch(`${this.endPoint[0]}/${params.flux.id}`, params.flux)
    return res.data
  },
  @log('FLUX - API - DELETE FLUX', 3)
  async deleteFlux(params) {
    const res = await this.client.delete(`${this.endPoint[0]}/${params.id}`)
    return res
  },
  @log('FLUX - API - RESET FLUX', 3)
  async resetFlux(params) {
    // post is quite useless, this is just to prevent a simple call from a browser
    const res = await this.client.post(`${this.endPoint[0]}/${params.flux.id}/fluxItems/reset`, {})
    return res
  }
}
