<template>
  <v-col cols="12" :md="width" class="pa-2">
    <v-card
      class="t-widget elevation-1 fill-height"
      :class="{ expanded: isExpanded, split: isSplit }"
      :style="[isSplit ? splitStyle : {}]"
    >
      <v-app-bar
        v-if="!isSplit"
        dense
        class="t-widget-header secondary elevation-1"
      >
        <v-toolbar-title class="title font-weight-light white--text pl-0">
          {{ title }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn v-if="isRefreshable" color="white" @click="refresh" icon>
          <v-icon>mdi-cog-refresh-outline</v-icon>
        </v-btn>
        <v-btn color="white" @click="toggleSplit" icon>
          <v-icon v-if="!isSplit">mdi-arrow-collapse-vertical</v-icon>
          <v-icon v-else>mdi-arrow-expand-vertical</v-icon>
        </v-btn>
        <v-btn v-if="isExpandable" color="white" @click="toggleExpand" icon>
          <v-icon v-if="!isExpanded">mdi-arrow-expand</v-icon>
          <v-icon v-else>mdi-arrow-collapse</v-icon>
        </v-btn>
        <v-btn v-if="!isExpanded && !isSplit" color="white" class="handle" icon>
          <v-icon>mdi-drag</v-icon>
        </v-btn>
      </v-app-bar>

      <v-card-text class="pa-0">
        <t-s-dashboard-map v-if="type === 'map'" />
        <t-s-dashboard-charts v-else-if="type === 'charts'" />
        <t-s-ad-widget v-else-if="type === 'ad'" :ad="fluxItem" />
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
import TSDashboardMap from './TSDashboardMap'
import TSDashboardCharts from './TSDashboardCharts'

import TSAdWidget from '@/components/flux/widgets/TSAdWidget'

export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    cols: {
      type: Number,
      default: 6,
    },
    fluxItem: {
      type: Object,
      default: () => ({}),
    },
    type: {
      type: String,
      default: '',
    },
    isExpandable: {
      type: Boolean,
      default: true,
    },
    isRefreshable: {
      type: Boolean,
      default: false,
    },
    isSplit: {
      type: Boolean,
      default: false,
    },
    splitStyle: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    TSAdWidget,
    TSDashboardMap,
    TSDashboardCharts,
  },
  data: () => ({
    isExpanded: false,
  }),
  computed: {
    width() {
      return this.isExpanded ? 12 : this.cols
    },
  },
  watch: {
    isSplit() {
      if (['streetView', 'map'].includes(this.type)) {
        this.$root.$emit('resizeMap')
      }
    },
  },
  methods: {
    refresh() {
      this.$emit('refresh', this.type)
    },
    toggleExpand() {
      this.isExpanded = !this.isExpanded
      this.$root.$emit('resizeMap')
    },
    toggleSplit() {
      this.$emit('split', !this.isSplit)
    },
    onLoaded() {
      this.$emit('loaded', this.type)
    },
  },
}
</script>

<style lang="scss" scoped>
.t-widget {
  max-height: 75vh;
  &.expanded {
    z-index: 200;
    position: fixed;
    max-height: 100vh;
    top: 0px;
    left: 0;
    right: 0;
  }

  &.split {
    z-index: 200;
    position: fixed;
    max-height: 100vh;
  }

  .t-widget-header {
    max-height: 48px;
  }
}
.v-card {
  display: flex !important;
  flex-direction: column;
}

.v-card__text {
  flex-grow: 1;
  overflow: auto;
}
</style>
