<template>
  <v-app id="ts-login-app">
    <v-main id="ts-login-main">
      <v-container class="fill-height">
        <v-row justify="center">
          <v-expand-x-transition>
            <v-card
              v-show="displayLogin"
              id="ts-login-card"
              elevation="18"
              outlined
            >
              <v-form>
                <v-container class="fill-height">
                  <v-row justify="center" align="center">
                    <v-col cols="12">
                      <v-icon dark x-large>
                        mdi-map-marker-radius-outline
                      </v-icon>
                      <span id="ts-login-title" class="ma-0 ml-3 white--text"
                        >Telescop Stelline</span
                      >
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        label="Email"
                        v-model="email"
                        hide-details
                        filled
                        outlined
                        dense
                        dark
                        autocomplete="username"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        @keyup.enter="logIn"
                        label="Password"
                        v-model="password"
                        type="password"
                        hide-details
                        filled
                        outlined
                        dense
                        dark
                        autocomplete="current-password"
                      />
                    </v-col>
                    <v-btn
                      @click="logIn"
                      class="ma-2"
                      elevation="8"
                      color="primary"
                      fab
                      dark
                    >
                      <v-icon>mdi-power</v-icon>
                    </v-btn>
                  </v-row>
                </v-container>
              </v-form>
            </v-card>
          </v-expand-x-transition>
        </v-row>
      </v-container>
      <t-snack-bar />
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import TSnackBar from '@/components/common/TSnackBar'
export default {
  name: 'Login',
  components: { TSnackBar },
  data() {
    return {
      displayLogin: false,
      email: '',
      password: '',
    }
  },
  mounted() {
    setTimeout(() => {
      this.displayLogin = true
    }, 300)
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
  },
  methods: {
    ...mapActions({
      login: 'auth/create',
    }),
    async logIn() {
      await this.login({ email: this.email, password: this.password }).catch(
        (e) => {
          this.openSnackbar(e, 'error')
        }
      )
      if (this.currentUser && this.currentUser.role === 'ADMIN') {
        this.$router.push({ name: 'Dashboard' })
      } else {
        this.$router.push({ name: 'Main' })
      }
    },
    openSnackbar(message, color) {
      this.$root.$emit('openSnackBar', {
        message: message,
        color: color,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
#ts-login-app {
  background-image: linear-gradient(
    to left bottom,
    #1f354f,
    #14617e,
    #0091a3,
    #34c2b8,
    #83f2be
  );
}

#ts-login-main::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  z-index: 0;
  background-image: url('../assets/images/worldMap.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

#ts-login-card {
  height: 280px;
  width: 453px;
  background: rgba(31, 53, 79, 0.9);
}

#ts-login-title {
  font-size: 2rem;
  font-weight: 100;
  text-shadow: 0 0 7px rgba(0, 0, 0, 0.5);
  vertical-align: middle;
}
</style>
