<template>
  <v-dialog :value="open" max-width="500px" @click:outside="close">
    <v-card class="pa-2">
      <v-card-title>
        <span class="headline font-weight-light">
          {{ title }}
        </span>
      </v-card-title>
      <v-card-text>
        <v-form>
          <v-container class="fill-height">
            <v-row justify="center" align="center">
              <v-col cols="12">
                <v-text-field
                  label="Email"
                  v-model="duser.email"
                  hide-details
                />
              </v-col>
              <v-col cols="12">
                <v-select
                  label="Rôle"
                  :items="roleItems"
                  :value="duser.customData.role"
                  @change="
                    (e) => {
                      duser.customData.role = e
                    }
                  "
                  hide-details
                >
                  <template v-slot:item="{ item }">
                    <v-chip :color="getRole(item).color" small label dark>
                      <v-icon small left>
                        {{ getRole(item).icon }}
                      </v-icon>
                      {{ item }}
                    </v-chip>
                  </template>
                  <template v-slot:selection="{ item }">
                    <v-chip
                      :color="getRole(item).color"
                      class="ma-2"
                      small
                      label
                      dark
                    >
                      <v-icon small left>
                        {{ getRole(item).icon }}
                      </v-icon>
                      {{ item }}
                    </v-chip>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="Nom"
                  v-model="duser.customData.lastname"
                  hide-details
                />
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="Prénom"
                  v-model="duser.customData.firstname"
                  hide-details
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Téléphone"
                  v-model="duser.customData.phone"
                  hide-details
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="primary" class="text-none" @click="close">
          Annuler
        </v-btn>
        <v-btn color="secondary" class="text-none" @click="save">
          Sauvegarder
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'TSUserDialogCreateUpdate',
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    user: {
      type: Object,
      default: () => ({
        customData: {},
      }),
    },
  },
  data() {
    return {
      duser: { customData: {} },
      roleItems: ['ADMIN', 'VALIDATOR', 'OPERATOR'],
    }
  },
  computed: {},
  watch: {
    user(val) {
      this.duser = val
    },
  },
  methods: {
    getRole(role) {
      switch (role) {
        case 'ADMIN':
          return { color: 'red', icon: 'mdi-shield-check' }
        case 'VALIDATOR':
          return { color: 'primary', icon: 'mdi-account-check' }
        case 'OPERATOR':
          return { color: 'teal', icon: 'mdi-account-search' }
        default:
          break
      }
    },
    close() {
      this.$emit('close', {})
    },
    save() {
      this.$emit('save', this.duser)
    },
  },
}
</script>

<style lang="scss" scoped></style>
