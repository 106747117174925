<template>
  <div>
    <v-row class="pa-6">
      <v-col cols="12" class="mt-5 text-h4 font-weight-light d-flex">
        <div class="white--text">Équipes</div>
        <v-spacer />
        <v-btn color="secondary" class="text-none" @click="onCreateTeam">
          <v-icon class="mr-2">mdi-account-plus</v-icon>
          Créer une équipe
        </v-btn>
      </v-col>
      <v-col cols="12" class="d-flex">
        <TSTeamsTable
          :teams="teams"
          :loading="loading"
          :totalTeams="totalTeams"
          @optionsChange="onOptionsChange"
          @edit="onEditTeam"
          @delete="onDeleteTeam"
        />
      </v-col>
    </v-row>
    <TSTeamDialogCreateUpdate
      :team="teamDialogCreateUpdateTeam"
      :open="teamDialogCreateUpdateOpen"
      :title="teamDialogCreateUpdateTitle"
      @close="teamDialogCreateUpdateOnClose"
      @save="teamDialogCreateUpdateOnSave"
    />
    <v-dialog
      :value="teamDialogDeleteOpen"
      max-width="800px"
      @click:outside="teamDialogDeleteOpen = false"
    >
      <v-card class="pa-2">
        <v-card-title class="headline font-weight-light">
          Êtes-vous sûr de vouloir supprimer cette équipe ?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            class="text-none"
            @click="teamDialogDeleteOpen = false"
          >
            Annuler
          </v-btn>
          <v-btn
            dark
            color="red"
            class="text-none"
            @click="onDeleteTeamConfirm"
          >
            <v-icon class="mr-3">mdi-alert</v-icon>
            Oui, supprimer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import TSTeamsTable from '@/components/team/TSTeamsTable'
import TSTeamDialogCreateUpdate from '@/components/team/TSTeamDialogCreateUpdate'

export default {
  name: 'Teams',
  components: { TSTeamsTable, TSTeamDialogCreateUpdate },
  data() {
    return {
      loading: true,
      teamDialogCreateUpdateOpen: false,
      teamDialogCreateUpdateTitle: '',
      teamDialogCreateUpdateTeam: null,
      teamDialogDeleteOpen: false,
      teamDialogDeleteId: null,
    }
  },
  mounted() {},
  methods: {
    ...mapActions({
      createTeam: 'teams/createTeam',
      deleteTeam: 'teams/deleteTeam',
      getTeams: 'teams/getTeams',
      updateTeam: 'teams/updateTeam',
    }),
    onCreateTeam() {
      this.teamDialogCreateUpdateTitle = ' Créer une équipe'
      this.teamDialogCreateUpdateTeam = {
        customData: {},
      }
      this.teamDialogCreateUpdateOpen = true
    },
    async onDeleteTeamConfirm() {
      if (this.teamDialogDeleteId) {
        this.loading = true
        const r = await this.deleteTeam({ id: this.teamDialogDeleteId }).catch(
          (e) => {
            this.$root.$emit('openSnackBar', {
              message: `L'équipe n'a pas été supprimée. ${e}`,
              color: 'error',
            })
            this.loading = false
          }
        )

        if (r) {
          this.$root.$emit('openSnackBar', {
            message: "L'équipe a bien été supprimée.",
            color: 'success',
          })
          this.teamDialogDeleteId = null
          this.teamDialogDeleteOpen = false
        }
        this.loading = false
      }
    },
    async onOptionsChange(options) {
      const params = {
        page: options.page - 1,
        size: options.itemsPerPage,
      }
      if (options.sortBy.length) {
        params.order = []
        options.sortBy.forEach((e, i) => {
          params.order.push([e, options.sortDesc[i] ? 'DESC' : 'ASC'])
        })
      } else {
        params.order = [['id', 'DESC']]
      }
      if (options.search && options.search !== '') {
        params.search = options.search
      }

      await this.getTeams({ params })
      this.loading = false
    },
    onDeleteTeam(id) {
      this.teamDialogDeleteId = id
      this.teamDialogDeleteOpen = true
    },
    onEditTeam(team) {
      this.teamDialogCreateUpdateTitle = " Modifier l'équipe"
      this.teamDialogCreateUpdateTeam = team
      this.teamDialogCreateUpdateOpen = true
    },
    teamDialogCreateUpdateOnClose() {
      this.teamDialogCreateUpdateOpen = false
    },
    async teamDialogCreateUpdateOnSave(team) {
      // edit mode
      if (team.id) {
        this.loading = true
        const r = await this.updateTeam({ team }).catch((e) => {
          this.$root.$emit('openSnackBar', {
            message: `L'équipe n'a pas été mise à jour. ${e}`,
            color: 'error',
          })
          this.loading = false
        })

        if (r) {
          this.$root.$emit('openSnackBar', {
            message: "L'équipe a bien été mise à jour.",
            color: 'success',
          })

          this.teamDialogCreateUpdateOpen = false
        }
        this.loading = false
      } else {
        // create mode
        const r = await this.createTeam({ team }).catch((e) => {
          this.$root.$emit('openSnackBar', {
            message: `L'équipe n'a pas été créée. ${e}`,
            color: 'error',
          })
          this.loading = false
        })

        if (r) {
          this.$root.$emit('openSnackBar', {
            message: "L'équipe a bien été créée.",
            color: 'success',
          })

          this.teamDialogCreateUpdateOpen = false
        }
        this.loading = false
      }
    },
  },
  computed: {
    ...mapGetters({
      teams: 'teams/teams',
      totalTeams: 'teams/totalTeams',
    }),
  },
}
</script>

<style lang="scss" scoped></style>
