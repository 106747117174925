var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ts-flux-items-table-container mt-6"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.fluxItems,"search":_vm.search,"options":_vm.options,"server-items-length":_vm.totalFluxItems,"loading":_vm.loading,"single-expand":true,"expanded":_vm.expanded,"show-expand":"","footer-props":{
      itemsPerPageOptions: [10, 50, 100, 500, 1000],
    }},on:{"update:options":function($event){_vm.options=$event},"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.origin",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","small":"","to":{
          name: _vm.isDuplicateFlux ? 'FluxDuplicateWork' : 'FluxBrowserWork',
          params: { itemId: item.origin },
        }}},[_vm._v(_vm._s(item.origin))])]}},{key:"item.id",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","small":"","to":{
          name: _vm.isDuplicateFlux ? 'FluxDuplicateWork' : 'FluxBrowserWork',
          params: { itemId: item.id },
        }}},[_vm._v(_vm._s(item.id))])]}},{key:"item.structuredData.isActive",fn:function(ref){
        var item = ref.item;
return [(item.structuredData.isActive)?_c('v-chip',{attrs:{"color":"secondary","small":"","outlined":"","label":"","dark":""}},[_c('v-icon',[_vm._v("mdi-play")])],1):_c('v-chip',{attrs:{"color":"red","small":"","outlined":"","label":"","dark":""}},[_c('v-icon',[_vm._v("mdi-stop")])],1)]}},{key:"item.state",fn:function(ref){
        var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.fluxItemsStates[item.state].color,"small":"","label":"","dark":""}},[_vm._v(" "+_vm._s(_vm.fluxItemsStates[item.state].label)+" ")])]}},{key:"item.finalAddress",fn:function(ref){
        var item = ref.item;
return [_c('small',[_vm._v(_vm._s(item.finalAddress))])]}},{key:"item.treatedOrSkippedAt",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.treatedOrSkippedAt))+" ")]}},{key:"item.validatedOrRejectedAt",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.validatedOrRejectedAt))+" ")]}},{key:"expanded-item",fn:function(ref){
        var headers = ref.headers;
        var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('pre',{staticClass:"py-3",domProps:{"innerHTML":_vm._s(
            _vm.syntaxHighlight(JSON.stringify(item.structuredData, null, 4))
          )}})])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }