const $axios = require('axios')
import { log } from '@/decorators'
/* eslint-disable */
export default {
  endPoint: [`/${process.env.VUE_APP_API_VERSION}`],
  client: $axios.create({
    withCredentials: true,
    baseURL: process.env.VUE_APP_API_URL,
    timeout: process.env.VUE_APP_API_TIMEOUT
  }),
  @log('GENERIC CLIENT - API - GET', 3)
  async get(route, params) {
    const res = await this.client.get(`${this.endPoint[0]}/${route}`, params)
    return res.data
  }
}