var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ts-users-table-container mt-6"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.users,"search":_vm.search,"options":_vm.options,"server-items-length":_vm.totalUsers,"loading":_vm.loading,"footer-props":{
      itemsPerPageOptions: [5, 10, 20, 50, 100],
    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"pa-5"},[_c('v-spacer'),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{staticClass:"elevation-1 grey--text",attrs:{"append-icon":"mdi-magnify","single-line":"","solo":"","dense":"","flat":"","hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('small',[_vm._v("Rechercher (email, rôle, nom, prénom)")])]},proxy:true}]),model:{value:(_vm.options.search),callback:function ($$v) {_vm.$set(_vm.options, "search", $$v)},expression:"options.search"}})],1)],1)]},proxy:true},{key:"item.customData.role",fn:function(ref){
    var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getRole(item.customData.role).color,"small":"","label":"","dark":""}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" "+_vm._s(_vm.getRole(item.customData.role).icon)+" ")]),_vm._v(" "+_vm._s(item.customData.role)+" ")],1)]}},{key:"item.createdAt",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")]}},{key:"item.updatedAt",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.updatedAt))+" ")]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","dark":"","icon":""},on:{"click":function($event){return _vm.editUser(item)}}},tooltip),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Modifier l'utilisateur")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.deleteUser(item)}}},tooltip),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Supprimer l'utilisateur")])])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }