import Vue from 'vue'
import { log, logSync } from '@/decorators'
import { filter, findIndex, get, orderBy } from 'lodash-es'
import fluxItemApi from '@/api/fluxItemApi'
import clientApi from '@/api/clientApi'

// initial state
const state = () => ({
  fluxItems: [],
  doublons: [],
  currentFluxItem: null,
  totalFluxItems: 0,
  totalPages: 0,
  currentPage: 0,
  picturesClasses: []
})

// getters
const getters = {
  doublons: (state) => {
    return state.doublons
  },
  fluxItems: (state) => {
    return state.fluxItems
  },
  currentFluxItem: (state) => {
    return state.currentFluxItem
  },
  totalFluxItems: (state) => {
    return state.totalFluxItems
  },
  totalPages: (state) => {
    return state.totalPages
  },
  currentPage: (state) => {
    return state.currentPage
  },
  picturesClasses: (state) => {
    return state.picturesClasses
  }
}
/* eslint-disable */
// actions
const actions = {
  @log('STORE - ACTION - FLUX ITEMS - GET DOUBLONS', 3)
  async getDoublons({ commit, state }, params) {
    const res = await fluxItemApi.getDoublons(params)
    commit('SET_DOUBLONS', res)
  },
  @log('STORE - ACTION - FLUX ITEMS - UPDATE DOUBLONS', 3)
  async updateDoublons({ commit, state }, params) {
    const res = await fluxItemApi.updateDoublons(params)
    //commit('SET_DOUBLONS', res)
  },
  @log('STORE - ACTION - FLUX ITEMS - GET FLUX', 3)
  async getFluxItems({ commit, state }, params) {
    const res = await fluxItemApi.getFluxItems(params)
    commit('SET_STATE', res)
  },  
  @log('STORE - ACTION - FLUX ITEMS - GET FLUX ITEM', 3)
  async getFluxItem({ commit, state }, params) {
    const fluxItem = await fluxItemApi.getFluxItem(params)
    commit('SET_CURRENT_FLUX_ITEM', fluxItem)
  },
  @log('STORE - ACTION - FLUX ITEMS - UPDATE FLUX', 3)
  async updateFluxItem({ commit, state }, params) {
    const fluxItem = await fluxItemApi.updateFluxItem(params)
    commit('SET_FLUX_ITEM', fluxItem)
    return fluxItem
  },
  @log('STORE - ACTION - FLUX ITEMS - GET FLUX ITEM MATCHES', 3)
  async getFluxItemMatches({ commit, state, rootState }, params) {
    const matches = await fluxItemApi.getFluxItemMatches(params)
    commit('SET_FLUXITEM_MATCHES', { matches, currentFlux: rootState.flux.currentFlux })
    commit('matches/SET_SELECTED_MATCHES', [], { root: true })
  },
  @log('STORE - ACTION - FLUX ITEMS - GET FLUX ITEM MATCHES', 3)
  async reloadMatches({ commit, state, rootState  }) {
    const matches = await fluxItemApi.getFluxItemMatches({ id:  state.currentFluxItem.id })
    commit('SET_FLUXITEM_MATCHES', { matches, currentFlux: rootState.flux.currentFlux })
    //commit('matches/SET_SELECTED_MATCHES', [], { root: true })
  },
  @log('STORE - ACTION - FLUX ITEMS - GET PICTURE SIMILARITIES', 3)
  async getPictureSimilarities({ commit, state, rootState  }, params) {
    return await clientApi.get(`fluxitems/${params.idA}/pictures/${params.idB}`)
    //commit('matches/SET_SELECTED_MATCHES', [], { root: true })
  },
  @log('STORE - ACTION - FLUX ITEMS - GET PICTURES CLASSES', 3)
  async getFluxItemPicturesClasses({ commit, state, rootState  }, params) {
    commit('SET_FLUXITEM_PICTURES_CLASSES', [])
    const picturesClasses = await clientApi.get(`fluxitems/${params.id}/pictures/indooroutdoor`)
    commit('SET_FLUXITEM_PICTURES_CLASSES', picturesClasses.res)
  }
}

// mutations
const mutations = {
  @logSync('STORE - MUTATION - FLUX ITEMS - SET FLUX ITEMS', 4)
  SET_STATE(state, res) {
    state.fluxItems = res.fluxItems
    state.totalFluxItems = res.totalItems
    state.totalPages = res.totalPages
    state.currentPage = res.currentPage
  },
  @logSync('STORE - MUTATION - FLUX ITEMS - SET CURRENT FLUX ITEM', 4)
  SET_CURRENT_FLUX_ITEM(state, fluxItem) {
    state.currentFluxItem = fluxItem
  },
  @logSync('STORE - MUTATION - FLUX ITEMS - SET DOUBLONS', 4)
  SET_DOUBLONS(state, doublons) {
    state.doublons = doublons
  },
  @logSync('STORE - MUTATION - FLUX ITEMS - SET FLUX ITEM', 4)
  SET_FLUX_ITEM(state, fluxItem) {
    const index = findIndex(state.fluxItems, f => { return f.id === fluxItem.id })
    if (index > -1) {
      Vue.set(state.fluxItems, index, fluxItem)
    }
    state.currentFluxItem = fluxItem
  },
  @logSync('STORE - MUTATION - FLUX ITEMS - SET FLUX ITEM MATCHES', 4)
  SET_FLUXITEM_MATCHES(state, { matches, currentFlux }) {
    const o = get(currentFlux, 'settings.options')

    const minScore = o && o.minScore ? o.minScore : 0
  
    const filteredMatches = filter(matches, (m) => {
      return m.score >= minScore
    })

    const sortedMatches = orderBy(filteredMatches, ['rejected', 'score'], ['asc', 'desc'])
    if(state.currentFluxItem.matches) {
      state.currentFluxItem.matches.splice(0, state.currentFluxItem.matches.length, ...sortedMatches)
    } else {
      state.currentFluxItem.matches = sortedMatches
    }
  },
  @logSync('STORE - MUTATION - FLUX ITEMS - UPDATE FLUX ITEM MATCH', 4)
  SET_FLUXITEM_PICTURES_CLASSES(state, picturesClasses) {
    state.picturesClasses = picturesClasses
    console.log(picturesClasses)
  },
  @logSync('STORE - MUTATION - FLUX ITEMS - UPDATE FLUX ITEM MATCH', 4)
  UPDATE_MATCH(state, match) {
    const index = findIndex(state.currentFluxItem.matches, m => { return m.id === match.id })
    if (index > -1) {
      Vue.set(state.currentFluxItem.matches, index, match)
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
