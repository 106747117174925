<template>
  <v-row no-gutters class="pa-4">
    <v-col v-for="(s, i) of stateFilters" :key="i" :sm="2">
      <v-checkbox
        @change="onStateFilterChange"
        :label="s.label"
        :color="s.color"
        v-model="s.value"
      />
    </v-col>
    <v-col :sm="12">
      <TSFluxItemsTable
        :loading="fluxItemsLoading"
        :flux="flux"
        :fluxItems="fluxItems"
        :totalFluxItems="totalFluxItems"
        @optionsChange="onOptionsChange"
      />
    </v-col>
  </v-row>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { fluxItemsStates } from '@/helpers/fluxItemsHelpers'
import TSFluxItemsTable from '@/components/flux/TSFluxItemsTable'

const setStateFilters = () => {
  const clone = Object.assign(fluxItemsStates)
  for (let m in clone) {
    clone[m].value = true
  }
  return clone
}

export default {
  name: 'TSFluxBrowserWidget',
  props: {},
  components: {
    TSFluxItemsTable,
  },
  data() {
    return {
      dflux: { settings: {} },
      fluxItemsLoading: true,
      options: null,
      stateFilters: setStateFilters(),
    }
  },
  computed: {
    ...mapGetters({
      flux: 'flux/currentFlux',
      fluxItems: 'fluxItems/fluxItems',
      totalFluxItems: 'fluxItems/totalFluxItems',
    }),
  },
  watch: {},
  methods: {
    ...mapActions({
      getFluxItems: 'fluxItems/getFluxItems',
    }),
    setParamsFromOptions() {
      const params = {
        fluxId: this.flux.id,
        page: this.options.page - 1,
        size: this.options.itemsPerPage,
        states: [],
      }
      if (this.options.sortBy.length) {
        params.order = []
        this.options.sortBy.forEach((e, i) => {
          params.order.push([e, this.options.sortDesc[i] ? 'DESC' : 'ASC'])
        })
      }

      for (let m in this.stateFilters) {
        if (this.stateFilters[m].value) {
          params.states.push(m)
        }
      }

      return params
    },
    async onOptionsChange(options) {
      this.options = options
      const params = this.setParamsFromOptions()

      this.fluxItemsLoading = true
      await this.getFluxItems({ params })
      this.fluxItemsLoading = false
    },
    async onStateFilterChange() {
      const params = this.setParamsFromOptions()

      this.fluxItemsLoading = true
      await this.getFluxItems({ params })
      this.fluxItemsLoading = false
    },
  },
}
</script>
<style lang="scss" scoped></style>
