import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import auth from '@/store/modules/auth'
import confirmation from '@/store/modules/confirmation'
import flux from '@/store/modules/flux'
import fluxItems from '@/store/modules/fluxItems'
import matches from '@/store/modules/matches'
import users from '@/store/modules/users'
import teams from '@/store/modules/teams'
import stats from '@/store/modules/stats'

/*import ads from '@/store/modules/ads'
import transactions from '@/store/modules/transactions' */

export default new Vuex.Store({
  modules: {
    auth,
    confirmation,
    flux,
    fluxItems,
    matches,
    users,
    teams,
    stats,
  },
})
