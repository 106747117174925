<template>
  <div>
    <v-row v-if="currentTeam" class="pa-6">
      <v-col cols="12" class="mt-5 text-h4 font-weight-light d-flex">
        <div class="white--text">
          <v-btn :to="{ name: 'Teams' }" dark class="mr-2" icon>
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <span class="f-title">{{ currentTeam.name }}</span>
          <br />
          <small class="ml-2 mt-4 f-desc">{{ currentTeam.description }}</small>
        </div>
        <v-spacer />
      </v-col>
      <v-col cols="12">
        <v-row class="f-group-list mt-5 mx-1 pa-3 white elevation-1">
          <v-col cols="12" class="d-flex pa-0">
            <v-chip color="red" class="mr-3" label dark>
              <v-icon small left>mdi-user</v-icon>
              équipiers
            </v-chip>
            <v-spacer />
          </v-col>
          <v-col cols="5" class="pa-0">
            <v-card flat class="fill-height pa-0">
              <v-card-title class="font-weight-light pb-2">
                <small
                  >{{ availableUsers.length }} disponible<span
                    v-if="availableUsers.length > 1"
                    >s</span
                  ></small
                >
              </v-card-title>
              <v-card-text class="pa-0">
                <draggable
                  class="f-list pa-1"
                  :list="availableUsers"
                  group="users"
                >
                  <v-hover
                    v-slot="{ hover }"
                    v-for="element in availableUsers"
                    :key="element.id"
                  >
                    <v-chip
                      class="f-list-item"
                      :class="[hover ? 'elevation-2' : 'elevation-0']"
                      :color="getRole(element.customData.role).color"
                      small
                      label
                      dark
                    >
                      <v-icon small left>
                        {{ getRole(element.customData.role).icon }}
                      </v-icon>
                      {{ element.email }}
                    </v-chip>
                  </v-hover>
                </draggable>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            cols="2"
            class="fill-height align-self-center pa-0"
            align="center"
          >
            <v-progress-circular
              v-if="updating"
              indeterminate
              color="primary"
            />
            <v-icon color="primary" v-else> mdi-arrow-left-right </v-icon>
          </v-col>
          <v-col cols="5" class="pa-0">
            <v-card flat class="fill-height pa-0">
              <v-card-title class="font-weight-light pb-2">
                <small
                  >{{ affectedUsers.length }} affecté<span
                    v-if="affectedUsers.length > 1"
                    >s</span
                  >
                  - Cocher le manager
                </small>
              </v-card-title>
              <v-card-text v-if="currentTeam" class="pa-0">
                <draggable
                  class="f-list pa-1"
                  v-model="affectedUsers"
                  group="users"
                >
                  <v-hover
                    v-slot="{ hover }"
                    v-for="element in affectedUsers"
                    :key="element.name"
                  >
                    <v-chip
                      class="f-list-item"
                      :class="[hover ? 'elevation-2' : 'elevation-0']"
                      :color="getRole(element.customData.role).color"
                      small
                      label
                      dark
                    >
                      <v-icon small left>
                        {{ getRole(element.customData.role).icon }}
                      </v-icon>
                      {{ element.email }}
                      <v-checkbox
                        class="ml-3 mr-0"
                        @change="setManager(element.id)"
                        :input-value="isManager(element.id)"
                      />
                    </v-chip>
                  </v-hover>
                </draggable>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="fill-height" v-else>
      <v-col cols="12" class="d-flex">
        <v-progress-linear indeterminate color="white" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { differenceWith } from 'lodash-es'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TSCommonMixin } from '@/mixins/TSCommonMixin'

export default {
  name: 'TeamEdit',
  components: {
    draggable,
  },
  mixins: [TSCommonMixin],
  data() {
    return {
      updating: false,
    }
  },
  computed: {
    ...mapGetters({
      currentTeam: 'teams/currentTeam',
      users: 'users/users',
    }),
    availableUsers: {
      get() {
        return differenceWith(this.users, this.affectedUsers, (a, b) => {
          return a.id === b.id
        })
      },
    },
    affectedUsers: {
      get() {
        return this.currentTeam ? this.currentTeam.users : []
      },
      async set(value) {
        this.updating = true
        await this.setUsersInTeam(value).catch((e) => {
          this.$root.$emit('openSnackBar', {
            message: `L'opération n'a pas pu aboutir. ${e}`,
            color: 'error',
          })
        })
        this.updating = false
      },
    },
  },
  methods: {
    ...mapActions({
      getTeamDetails: 'teams/getTeamDetails',
      updateTeam: 'teams/updateTeam',
      setUsersInTeam: 'teams/setUsersInTeam',
      setManager: 'teams/setManager',
      getUsers: 'users/getUsers',
    }),
    ...mapMutations({
      setCurrentTeam: 'teams/SET_CURRENT_TEAM',
    }),
    getRole(role) {
      switch (role) {
        case 'ADMIN':
          return { color: 'red', icon: 'mdi-shield-check' }
        case 'VALIDATOR':
          return { color: 'primary', icon: 'mdi-account-check' }
        case 'OPERATOR':
          return { color: 'teal', icon: 'mdi-account-search' }
        default:
          break
      }
    },
    isManager(id) {
      return this.currentTeam && this.currentTeam.manager === id
    },
  },
  async created() {
    this.updating = true
    const params = { size: 1000 }
    await this.getUsers({ params }) // hack
    await this.getTeamDetails({ id: this.$route.params.id })
    this.updating = false
  },
  destroy() {
    this.setCurrentTeam(null)
  },
}
</script>

<style lang="scss" scoped>
.f-title {
  display: inline-block;
  vertical-align: middle;
}
.f-desc {
  display: inline-block;
  line-height: 1.3em;
  max-width: 500px;
  font-size: 16px;
}
.f-group-list {
  border-radius: 4px;
}

.f-list {
  height: 180px;
  overflow: auto;
  border-radius: 6px;
  background: rgba(20, 97, 126, 0.05);
  border: 1px solid rgba(20, 97, 126, 0.2);
  &.larger {
    height: 240px;
  }
}

.f-list-item {
  border-radius: 4px;
  margin: 4px;
  cursor: pointer;
  min-height: 32px;
  line-height: 32px;
  text-align: center;
  background: rgba(20, 97, 126, 0.05);
}
</style>
