<template>
  <div>
    <v-row class="fill-height" v-if="loading">
      <v-col cols="12" class="d-flex">
        <v-progress-linear indeterminate color="white" />
      </v-col>
    </v-row>
    <div v-else-if="msg !== ''">
      <v-col cols="12" class="mt-5 text-h4 font-weight-light d-flex">
        <div class="white--text">
          <span class="f-title">{{ msg }}</span>
        </div>
      </v-col>
    </div>
    <div v-else>
      <v-row
        no-gutters
        class="action"
        :class="[split ? 'primary split pa-2' : '']"
        align="center"
      >
        <v-col align="right">
          <h3 class="white--text">Est-ce la même annonce ?</h3>
        </v-col>
        <v-col align="left">
          <v-btn class="mx-2" dark color="green" @click="setIsDoublon(true)"
            >OUI</v-btn
          >
          <v-btn class="mx-2" dark color="warning" @click="setIsDoublon(false, true)"
            >JE NE SAIS PAS</v-btn
          >
          <v-btn class="mx-2" dark color="red" @click="setIsDoublon(false)"
            >NON</v-btn
          >
        </v-col>
      </v-row>
      <draggable
        :list="widgets"
        handle=".handle"
        group="widgets"
        class="row px-3"
      >
        <t-s-diff-widget
          v-for="w in widgets"
          v-bind="w"
          :fluxItemA="fluxItemA"
          :fluxItemB="fluxItemB"
          :similarities="similarities"
          :key="w.id"
          :isSplit="split"
          @split="onSplit"
        />
      </draggable>
      <v-btn
        v-show="split"
        id="unsplit-btn"
        color="orange"
        dark
        @click="onSplit(false)"
        fab
        transition="scale-transition"
      >
        <v-icon>mdi-arrow-collapse-vertical</v-icon>
      </v-btn>
    </div>
    <t-s-photo-gallery />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { groupBy, map } from 'lodash-es'
import draggable from 'vuedraggable'

import { TSCommonMixin } from '@/mixins/TSCommonMixin'

import TSDiffWidget from '@/components/flux/widgets/TSDiffWidget'
import TSPhotoGallery from '@/components/common/TSPhotoGallery'
export default {
  name: 'FluxDuplicate',
  components: {
    draggable,
    TSDiffWidget,
    TSPhotoGallery,
  },
  mixins: [TSCommonMixin],
  data: () => {
    return {
      cursor: 0,
      split: false,
      loading: true,
      similarities: [],
      widgets: [
        {
          id: 'a',
          title: 'Annonces',
          type: 'ad',
          cols: 12,
          isExpandable: false,
          splitStyle: {
            width: '100vw',
            top: '52px',
            left: 0,
            height: 'calc(60vh - 52px)',
          },
        },
        {
          id: 'b',
          title: 'Photos A',
          type: 'photosA',
          cols: 6,
          columns: 3,
          isExpandable: false,
          splitStyle: {
            width: '50vw',
            bottom: 0,
            left: 0,
            height: '40vh',
          },
        },
        {
          id: 'c',
          title: 'Photos B',
          type: 'photosB',
          cols: 6,
          columns: 3,
          isExpandable: false,
          splitStyle: {
            width: '50vw',
            bottom: 0,
            right: 0,
            height: '40vh',
          },
        },
      ],
    }
  },
  created() {
    this.load(this.$route.params.id, this.$route.params.itemId)
    this.$root.$on('next', this.onNext)
  },
  destroyed() {
    this.setCurrentFlux(null)
    this.$root.$off('next', this.onNext)
  },
  watch: {
    $route(to) {
      this.load(to.params.id, to.params.itemId)
    },
    //fluxItemA(newItem) {
    //  if (newItem && this.fluxItemB) {
    //     this.getPictureSimilarities({idA: newItem.id, idB: this.fluxItemB.id})
    //  }
    // },
    fluxItemB: {
      handler: async function(newItem, oldItem) {
        if (!this.loading && newItem !== oldItem && this.fluxItemA) {
          this.similarities = await this.getPictureSimilarities({idA:this.fluxItemA.id, idB: newItem.id})
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      currentFlux: 'flux/currentFlux',
      fluxItem: 'fluxItems/currentFluxItem',
      doublons: 'fluxItems/doublons',
      fluxStats: 'stats/fluxStats',
    }),
    showNextBtn() {
      return this.currentFlux && this.role === 'OPERATOR'
    },
    fluxItemA() {
      return this.fluxItem || {}
    },
    fluxItemB() {
      return this.doublonsByOrigins[this.currentOrigin][0]
    },
    doublonsByOrigins() {
      if (this.doublons && this.doublons.length) {
        let grouped = groupBy(this.doublons, 'origin')
        if (grouped['null']) {
          const subGrouped = groupBy(grouped['null'], 'id')
          grouped = { ...grouped, ...subGrouped }
          delete grouped['null']
        }

        return grouped
      } else {
        return {}
      }
    },
    currentOrigin() {
      const keys = Object.keys(this.doublonsByOrigins)
      if (keys && keys.length) {
        return keys[this.cursor]
      } else return undefined
    },
  },
  methods: {
    ...mapActions({
      getFluxItem: 'fluxItems/getFluxItem',
      getDoublons: 'fluxItems/getDoublons',
      updateDoublons: 'fluxItems/updateDoublons',
      updateFluxItem: 'fluxItems/updateFluxItem',
      getPictureSimilarities: 'fluxItems/getPictureSimilarities',
      getFluxDetails: 'flux/getFluxDetails',
      getFluxStats: 'stats/getFluxStats',
    }),
    ...mapMutations({
      setCurrentFlux: 'flux/SET_CURRENT_FLUX',
      setFluxItemPicturesClasses: 'fluxItems/SET_FLUXITEM_PICTURES_CLASSES'
    }),
    async loadItem(fluxId, fluxItemId) {
      this.similarities = []
      const params = fluxItemId ? { fluxItemId } : { fluxId }
      await this.getFluxItem({ params })

      if (fluxId) {
        this.getFluxStats({ fluxId, params: {state: this.role === 'VALIDATOR' ? 'treated' : 'created'} })
      }

      if (this.fluxItem) {
        this.setFluxItemPicturesClasses([])
        await this.getDoublons({ id: this.fluxItem.id })
        this.loading = false
        this.startDate = new Date()
        this.similarities = await this.getPictureSimilarities({idA:this.fluxItemA.id, idB: this.fluxItemB.id})
      } else {
        this.msg = 'Aucun item à traiter pour ce flux ...'
        this.loading = false
      }
    },
    async load(fluxId, itemId) {
      this.msg = ''
      this.loading = true
      await this.getFluxDetails({ id: fluxId })
      if (itemId) {
        this.loadItem(null, itemId)
      } else {
        this.loadItem(fluxId)
      }
    },
    async setIsDoublon(isDoublon, dontKnow = false) {
      const ids = map(this.doublonsByOrigins[this.currentOrigin], 'id')
      await this.updateDoublons({
        id: this.fluxItemA.id,
        toCheckIds: ids,
        isDoublon,
        dontKnow
      })

      if (this.cursor + 1 < Object.keys(this.doublonsByOrigins).length) {
        this.cursor++
      } else {
        this.cursor = 0
        this.next()
      }
    },
    async next() {
      this.msg = ''
      this.loading = true

      if (this.$route.params.itemId) {
        this.$router.push({
          name: 'FluxBrowser',
          params: { id: this.$route.params.id },
        })
      } else {
        this.loadItem(this.$route.params.id)
      }
    },
    onSplit(v) {
      this.split = v
    },
  },
}
</script>

<style lang="scss" scoped>
#unsplit-btn {
  position: fixed;
  bottom: 10px;
  left: 25px;
  z-index: 200;
}
.f-title {
  font-size: 1.5rem !important;
  display: inline-block;
  vertical-align: middle;
}

.full {
  overflow: visible !important;
  white-space: normal !important;
}

.action {
  &.split {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 200;
  }
}
</style>
