import { log, logSync } from '@/decorators'
import Vue from 'vue'
import clientApi from '@/api/clientApi'

// initial state
const state = () => ({
  stats: null,
  fluxItemStats: {
    created: [],
    treated: [],
    validated: [],
    new: [],
    ratiodoublon: []
  },
  fluxStats: {},
  operatorStats: null,
})

// getters
const getters = {
  stats: (state) => state.stats,
  fluxStats: (state) => state.fluxStats,
  fluxItemStats: (state) => state.fluxItemStats,
  operatorStats: (state) => state.operatorStats,
}
/* eslint-disable */
// actions
const actions = {
  @log('STORE - ACTION - STATS - GET STATS', 3)
  async getStats({ commit, state }, { params }) {
    const { stats } = await clientApi.get(`stats`, { params })
    commit('SET_STATS', stats)
  },
  async getFluxStats({ commit, state }, { fluxId, params }) {
    const { total, stats } = await clientApi.get(`stats/flux/${fluxId}`, { params })
    stats.total = total
    commit('SET_FLUX_STATS', { fluxId, stats })
  },
  @log('STORE - ACTION - STATS - GET OPERATOR STATS', 3)
  async getOperatorStats({ commit, state }, { fluxId, teamId, params }) {
    const { stats } = teamId ?  await clientApi.get(`stats/teams/${teamId}/operator`, { params }) : await clientApi.get(`stats/flux/${fluxId}/operator`, { params })
    commit('SET_OPERATOR_STATS', stats)
  },
  @log('STORE - ACTION - STATS - GET FLUXITEMS STATS', 3)
  async getFluxItemsStats({ commit, state }, { params }) {
    const { stats } = await clientApi.get(`stats/fluxitems/${params.type}`, { params })
    commit('SET_FLUXITEMS_STATS', { days: stats.days, type: params.type })
  }
}

// mutations
const mutations = {
  @logSync('STORE - MUTATION - STATS - SET STATS', 4)
  SET_STATS: (state, stats) => {
    state.stats = stats
  },
  @logSync('STORE - MUTATION - STATS - SET FLUX STATS', 4)
  SET_FLUX_STATS: (state, {fluxId, stats}) => {
    Vue.set(state.fluxStats, fluxId, stats)
  },
  @logSync('STORE - MUTATION - STATS - SET OPERATOR STATS', 4)
  SET_OPERATOR_STATS: (state, stats) => {
    state.operatorStats = stats
  },
  @logSync('STORE - MUTATION - STATS - SET FLUXITEMS STATS', 4)
  SET_FLUXITEMS_STATS: (state, obj) => {
    state.fluxItemStats[obj.type] = obj.days
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}