<template>
  <div>
    <v-row v-if="currentFlux" class="pa-6">
      <TSFluxDialogTester
        :flux="currentFlux"
        :open="fluxDialogTesterOpen"
        @close="fluxDialogTesterOnClose"
      />
      <v-col cols="12" class="mt-5 text-h4 font-weight-light d-flex">
        <div class="white--text">
          <v-btn :to="{ name: 'Flux' }" dark class="mr-2" icon>
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <span class="f-title">{{ currentFlux.name }}</span>
          <br />
          <small class="ml-2 mt-4 f-desc">{{ currentFlux.description }}</small>
        </div>
        <v-spacer />
        <v-btn
          :color="isActive ? 'secondary' : 'grey'"
          class="text-none"
          dark
          @click="toggleFlux"
        >
          <v-icon small left>
            {{
              !isActive ? 'mdi-play-circle-outline' : 'mdi-pause-circle-outline'
            }}
          </v-icon>
          {{ isActive ? 'Désactiver' : 'Activer' }} le Flux
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-row class="f-group-list mt-5 mx-1 pa-3 white elevation-1">
          <v-col cols="12" class="d-flex pa-0">
            <v-chip color="red" class="mr-3" label dark>
              <v-icon small left> mdi-filter-variant </v-icon>
              FILTRES
            </v-chip>
            <v-spacer />
            <v-btn
              text
              class="text-none primary--text"
              @click="fluxDialogTesterOpen = true"
            >
              Tester
            </v-btn>
          </v-col>
          <v-col cols="12" md="3">
            <v-select
              class="mb-3"
              v-model="filterToAdd.column"
              :items="filterColumns"
              label="Colonne"
              hide-details
            />
            <v-select
              class="mb-3"
              v-model="filterToAdd.op"
              :items="ops"
              label="Opérateur"
              hide-details
            />
            <v-textarea
              v-model="filterToAdd.value"
              class="mb-5"
              label="Valeur"
              rows="2"
              hide-details
            />
            <v-btn block color="primary" outlined @click="addFilter">
              Ajouter le filtre</v-btn
            >
          </v-col>
          <v-col
            cols="12"
            md="1"
            class="fill-height align-self-center pa-0"
            align="center"
          >
            <v-progress-circular
              v-if="updating"
              indeterminate
              color="primary"
            />
            <v-icon color="primary" v-else>
              {{
                $vuetify.breakpoint.mdAndUp
                  ? 'mdi-arrow-right'
                  : 'mdi-arrow-down'
              }}
            </v-icon>
          </v-col>
          <v-col cols="12" md="8">
            <v-card flat class="fill-height pa-0">
              <v-card-text class="pa-0">
                <draggable
                  class="f-list larger pa-1"
                  :list="filters"
                  group="filters"
                >
                  <v-hover
                    v-slot="{ hover }"
                    v-for="(f, i) in filters"
                    :key="i"
                  >
                    <div
                      class="f-list-item"
                      :class="[hover ? 'elevation-2' : 'elevation-0']"
                    >
                      {{ f.column }} {{ f.op }} {{ f.value }}
                      <v-btn
                        class="float-right"
                        @click="removeFilter(i)"
                        small
                        icon
                      >
                        <v-icon small>mdi-delete</v-icon>
                      </v-btn>
                    </div>
                  </v-hover>
                </draggable>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col> <v-col cols="12">
        <v-row class="f-group-list mt-5 mx-1 pa-3 white elevation-1">
          <v-col cols="12" class="d-flex pa-0">
            <v-chip color="red" class="mr-3" label dark>
              <v-icon small left> mdi-filter-variant </v-icon>
              FILTRES DE PRIORITÉS
            </v-chip>
            <v-spacer />
            <v-btn
              text
              class="text-none primary--text"
              @click="fluxDialogTesterOpen = true"
            >
              Tester
            </v-btn>
          </v-col>
          <v-col cols="5" class="pa-0">
            <v-card flat class="fill-height pa-0">
              <v-card-title class="font-weight-light pb-2">
                <small
                  >{{ availablePriorityFilters.length }} disponible<span
                    v-if="availablePriorityFilters.length > 1"
                    >s</span
                  ></small
                >
              </v-card-title>
              <v-card-text class="pa-0">
                <draggable
                  class="f-list pa-1"
                  :list="availablePriorityFilters"
                  group="priority"
                >
                  <v-hover
                    v-slot="{ hover }"
                    v-for="id in availablePriorityFilters"
                    :key="id"
                  >
                    <div
                      class="f-list-item"
                      :class="[hover ? 'elevation-2' : 'elevation-0']"
                    >
                     {{ getPFilter(id) }}
                    </div>
                  </v-hover>
                </draggable>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            cols="2"
            class="fill-height align-self-center pa-0"
            align="center"
          >
            <v-progress-circular
              v-if="updating"
              indeterminate
              color="primary"
            />
            <v-icon color="primary" v-else> mdi-arrow-left-right </v-icon>
          </v-col>
          <v-col cols="5" class="pa-0">
            <v-card flat class="fill-height pa-0">
              <v-card-title class="font-weight-light pb-2">
                <small
                  >{{ affectedPriorityFilters.length }} affecté<span
                    v-if="affectedPriorityFilters.length > 1"
                    >s</span
                  ></small
                >
              </v-card-title>
              <v-card-text class="pa-0">
                <draggable
                  class="f-list pa-1"
                  v-model="affectedPriorityFilters"
                  group="priority"
                >
                  <v-hover
                    v-slot="{ hover }"
                    v-for="id in affectedPriorityFilters"
                    :key="id"
                  >
                    <div
                      class="f-list-item"
                      :class="[hover ? 'elevation-2' : 'elevation-0']"
                    >
                      {{ getPFilter(id) }}
                    </div>
                  </v-hover>
                </draggable>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-row class="f-group-list mt-5 mx-1 pa-3 white elevation-1">
          <v-col cols="12" class="d-flex pa-0">
            <v-chip color="red" class="mr-3" label dark>
              <v-icon small left> mdi-cog </v-icon>
              OPTIONS
            </v-chip>
            <v-spacer />
          </v-col>
          <v-col
            cols="12"
            class="fill-height align-self-center py-5 px-3"
            align="center"
          >
            <v-row>
              <v-col cols="6" md="4">
                <v-text-field
                  class="ml-3"
                  v-model="minScore"
                  label="Score minimal"
                  type="number"
                  max="100"
                  min="0"
                  hide-details
                />
              </v-col>
              <v-col cols="6" md="4">
                <v-checkbox
                  v-model="disableProofMode"
                  class="ml-3"
                  label="Désactiver la demande de preuve"
                  color="primary"
                  hide-details
                />
              </v-col>
              <v-col cols="6" md="4">
                <v-checkbox
                  v-model="disableAnimation"
                  class="ml-3"
                  label="Désactiver les animations"
                  color="primary"
                  hide-details
                />
              </v-col>
              <v-col cols="6" md="4">
                <v-checkbox
                  v-model="disableMultiSelection"
                  class="ml-3"
                  label="Désactiver la multi-sélection"
                  color="primary"
                  hide-details
                />
              </v-col>
              <v-col cols="6" md="4">
                <v-checkbox
                  v-model="isARemoveDuplicateFlux"
                  class="ml-3"
                  label="Flux de dédoublonnage"
                  color="primary"
                  hide-details
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6">
        <v-row class="f-group-list mt-5 mx-1 pa-3 white elevation-1">
          <v-col cols="12" class="d-flex pa-0">
            <v-chip :color="getRole('OPERATOR').color" class="mr-3" label dark>
              <v-icon small left>
                {{ getRole('OPERATOR').icon }}
              </v-icon>
              OPERATOR
            </v-chip>
            <v-spacer />
            <v-btn
              text
              class="text-none primary--text"
              @click="reset('OPERATOR')"
            >
              Réinitialiser
            </v-btn>
          </v-col>
          <v-col cols="5" class="pa-0">
            <v-card flat class="fill-height pa-0">
              <v-card-title class="font-weight-light pb-2">
                <small
                  >{{ availableOperators.length }} disponible<span
                    v-if="availableOperators.length > 1"
                    >s</span
                  ></small
                >
              </v-card-title>
              <v-card-text class="pa-0">
                <draggable
                  class="f-list pa-1"
                  :list="availableOperators"
                  group="operators"
                >
                  <v-hover
                    v-slot="{ hover }"
                    v-for="element in availableOperators"
                    :key="element.id"
                  >
                    <div
                      class="f-list-item"
                      :class="[hover ? 'elevation-2' : 'elevation-0']"
                    >
                      {{ element.email }}
                    </div>
                  </v-hover>
                </draggable>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            cols="2"
            class="fill-height align-self-center pa-0"
            align="center"
          >
            <v-progress-circular
              v-if="updating"
              indeterminate
              color="primary"
            />
            <v-icon color="primary" v-else> mdi-arrow-left-right </v-icon>
          </v-col>
          <v-col cols="5" class="pa-0">
            <v-card flat class="fill-height pa-0">
              <v-card-title class="font-weight-light pb-2">
                <small
                  >{{ affectedOperators.length }} affecté<span
                    v-if="affectedOperators.length > 1"
                    >s</span
                  ></small
                >
              </v-card-title>
              <v-card-text class="pa-0">
                <draggable
                  class="f-list pa-1"
                  v-model="affectedOperators"
                  group="operators"
                >
                  <v-hover
                    v-slot="{ hover }"
                    v-for="element in affectedOperators"
                    :key="element.name"
                  >
                    <div
                      class="f-list-item"
                      :class="[hover ? 'elevation-2' : 'elevation-0']"
                    >
                      {{ element.email }}
                    </div>
                  </v-hover>
                </draggable>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6">
        <v-row class="f-group-list mt-5 mx-1 pa-3 white elevation-1">
          <v-col cols="12" class="d-flex pa-0">
            <v-chip :color="getRole('VALIDATOR').color" class="mr-3" label dark>
              <v-icon small left>
                {{ getRole('VALIDATOR').icon }}
              </v-icon>
              VALIDATOR
            </v-chip>
            <v-spacer />
            <v-btn
              text
              class="text-none primary--text"
              @click="reset('VALIDATOR')"
            >
              Réinitialiser
            </v-btn>
          </v-col>
          <v-col cols="5" class="pa-0">
            <v-card flat class="fill-height pa-0">
              <v-card-title class="font-weight-light pb-2">
                <small
                  >{{ availableValidators.length }} disponible<span
                    v-if="availableValidators.length > 1"
                    >s</span
                  ></small
                >
              </v-card-title>
              <v-card-text class="pa-0">
                <draggable
                  class="f-list pa-1"
                  :list="availableValidators"
                  group="validators"
                >
                  <v-hover
                    v-slot="{ hover }"
                    v-for="element in availableValidators"
                    :key="element.id"
                  >
                    <div
                      class="f-list-item"
                      :class="[hover ? 'elevation-2' : 'elevation-0']"
                    >
                      {{ element.email }}
                    </div>
                  </v-hover>
                </draggable>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            cols="2"
            class="fill-height align-self-center pa-0"
            align="center"
          >
            <v-progress-circular
              v-if="updating"
              indeterminate
              color="primary"
            />
            <v-icon color="primary" v-else> mdi-arrow-left-right </v-icon>
          </v-col>
          <v-col cols="5" class="pa-0">
            <v-card flat class="fill-height pa-0">
              <v-card-title class="font-weight-light pb-2">
                <small
                  >{{ affectedValidators.length }} affecté<span
                    v-if="affectedValidators.length > 1"
                    >s</span
                  ></small
                >
              </v-card-title>
              <v-card-text class="pa-0">
                <draggable
                  class="f-list pa-1"
                  v-model="affectedValidators"
                  group="validators"
                >
                  <v-hover
                    v-slot="{ hover }"
                    v-for="element in affectedValidators"
                    :key="element.id"
                  >
                    <div
                      class="f-list-item"
                      :class="[hover ? 'elevation-2' : 'elevation-0']"
                    >
                      {{ element.email }}
                    </div>
                  </v-hover>
                </draggable>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="fill-height" v-else>
      <v-col cols="12" class="d-flex">
        <v-progress-linear indeterminate color="white" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { differenceWith, filter, find, get, map } from 'lodash-es'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TSCommonMixin } from '@/mixins/TSCommonMixin'
import TSFluxDialogTester from '@/components/flux/TSFluxDialogTester'

export default {
  name: 'FluxEdit',
  components: {
    draggable,
    TSFluxDialogTester,
  },
  mixins: [TSCommonMixin],
  data() {
    return {
      filterToAdd: {
        column: undefined,
        op: undefined,
        value: undefined,
      },
      updating: false,
      fluxDialogTesterOpen: false,
    }
  },
  computed: {
    ...mapGetters({
      currentFlux: 'flux/currentFlux',
      filterColumns: 'flux/filterColumns',
      priorityFilters: 'flux/priorityFilters',
      operators: 'users/operators',
      validators: 'users/validators',
    }),
    availableOperators: {
      get() {
        return differenceWith(
          this.operators,
          this.affectedOperators,
          (a, b) => {
            return a.id === b.id
          }
        )
      },
    },
    affectedOperators: {
      get() {
        return this.currentFlux
          ? filter(this.currentFlux.users, (u) => {
              return get(u, 'customData.role') === 'OPERATOR'
            })
          : []
      },
      async set(value) {
        this.updating = true
        await this.setUsersInFlux(value.concat(this.affectedValidators)).catch(
          (e) => {
            this.$root.$emit('openSnackBar', {
              message: `L'opération n'a pas pu aboutir. ${e}`,
              color: 'error',
            })
          }
        )
        this.updating = false
      },
    },
    availableValidators: {
      get() {
        return differenceWith(
          this.validators,
          this.affectedValidators,
          (a, b) => {
            return a.id === b.id
          }
        )
      },
    },
    affectedValidators: {
      get() {
        return this.currentFlux
          ? filter(this.currentFlux.users, (u) => {
              return get(u, 'customData.role') === 'VALIDATOR'
            })
          : []
      },
      async set(value) {
        this.updating = true
        await this.setUsersInFlux(value.concat(this.affectedOperators)).catch(
          (e) => {
            this.$root.$emit('openSnackBar', {
              message: `L'opération n'a pas pu aboutir. ${e}`,
              color: 'error',
            })
          }
        )

        this.updating = false
      },
    },
    minScore: {
      get() {
        const o = get(this, 'currentFlux.settings.options')
        return o && o.minScore ? o.minScore : 0
      },
      async set(value) {
        this.updating = true
        await this.updateFlux({
          flux: {
            id: this.currentFlux.id,
            'settings.options.minScore': parseInt(value),
          },
        })
        this.updating = false
      },
    },
    disableAnimation: {
      get() {
        const o = get(this, 'currentFlux.settings.options')
        return o && o.disableAnimation ? o.disableAnimation : false
      },
      async set(value) {
        this.updating = true
        await this.updateFlux({
          flux: {
            id: this.currentFlux.id,
            'settings.options.disableAnimation': value,
          },
        })
        this.updating = false
      },
    },
    disableMultiSelection: {
      get() {
        const o = get(this, 'currentFlux.settings.options')
        return o && o.disableMultiSelection ? o.disableMultiSelection : false
      },
      async set(value) {
        this.updating = true
        await this.updateFlux({
          flux: {
            id: this.currentFlux.id,
            'settings.options.disableMultiSelection': value,
          },
        })
        this.updating = false
      },
    },
    disableProofMode: {
      get() {
        const o = get(this, 'currentFlux.settings.options')
        return o && o.disableProofMode ? o.disableProofMode : false
      },
      async set(value) {
        this.updating = true
        await this.updateFlux({
          flux: {
            id: this.currentFlux.id,
            'settings.options.disableProofMode': value,
          },
        })
        this.updating = false
      },
    },
    isARemoveDuplicateFlux: {
      get() {
        const o = get(this, 'currentFlux.settings.options')
        return o && o.isARemoveDuplicateFlux ? o.isARemoveDuplicateFlux : false
      },
      async set(value) {
        this.updating = true
        await this.updateFlux({
          flux: {
            id: this.currentFlux.id,
            'settings.options.isARemoveDuplicateFlux': value,
          },
        })
        this.updating = false
      },
    },
    filters() {
      const f = get(this.currentFlux, 'settings.filters')
      return f || []
    },
    availablePriorityFilters: {
      get() {
        return map(differenceWith(
          this.priorityFilters,
          this.affectedPriorityFilters,
          (a, b) => {
            return a.id === b
          }
        ), 'id')
      },
    },
    affectedPriorityFilters: {
      get() {
        return get(this.currentFlux, 'settings.priorityFilters') || []
      },
      async set(value) {
        this.updating = true
        console.log(value)
        await this.setPriorityFiltersInFlux(value).catch(
          (e) => {
            this.$root.$emit('openSnackBar', {
              message: `L'opération n'a pas pu aboutir. ${e}`,
              color: 'error',
            })
          }
        )

        this.updating = false
      },
    },
    isActive() {
      return this.currentFlux ? this.currentFlux.active : false
    },
    ops() {
      return [
        '=',
        '<>',
        '<',
        '<=',
        '>',
        '>=',
        'BETWEEN',
        'NOT BETWEEN',
        'LIKE',
        'NOT LIKE',
        'IN',
        'NOT IN',
        'IS',
        'IS NOT',
      ]
    },
  },
  methods: {
    ...mapActions({
      getFluxDetails: 'flux/getFluxDetails',
      getPriorityFilters: 'flux/getPriorityFilters',
      updateFlux: 'flux/updateFlux',
      setPriorityFiltersInFlux: 'flux/setPriorityFiltersInFlux',
      setUsersInFlux: 'flux/setUsersInFlux',
      getOperators: 'users/getOperators',
      getValidators: 'users/getValidators',
      resetFluxDetails: 'flux/resetDetails',
    }),
    ...mapMutations({
      setCurrentFlux: 'flux/SET_CURRENT_FLUX',
    }),
    async addFilter() {
      this.filters.push(this.filterToAdd)
      await this.updateFlux({
        flux: { id: this.currentFlux.id, 'settings.filters': this.filters },
      })

      this.filterToAdd = {
        column: undefined,
        op: undefined,
        value: undefined,
      }
    },
    fluxDialogTesterOnClose() {
      this.fluxDialogTesterOpen = false
    },
    getPFilter(id) {
      const f = find(this.priorityFilters, {id})
      return f ? f.title : '...'
    },
    toggleFlux() {
      this.updateFlux({
        flux: { id: this.currentFlux.id, active: !this.currentFlux.active },
      })
    },
    removeFilter(idx) {
      this.filters.splice(idx, 1)
      this.updateFlux({
        flux: { id: this.currentFlux.id, 'settings.filters': this.filters },
      })
    },
    async reset(type) {
      this.updating = true
      if (type === 'OPERATOR') {
        await this.setUsersInFlux(this.affectedValidators)
      } else if (type === 'VALIDATOR') {
        await this.setUsersInFlux(this.affectedOperators)
      }
      this.updating = false
    },
  },
  async created() {
    this.updating = true
    await this.getFluxDetails({ id: this.$route.params.id })
    await this.getPriorityFilters()
    await this.getOperators()
    await this.getValidators()
    this.updating = false
  },
  destroy() {
    this.setCurrentFlux(null)
  },
}
</script>

<style lang="scss" scoped>
.f-title {
  display: inline-block;
  vertical-align: middle;
}
.f-desc {
  display: inline-block;
  line-height: 1.3em;
  max-width: 500px;
  font-size: 16px;
}
.f-group-list {
  border-radius: 4px;
}

.f-list {
  height: 180px;
  overflow: auto;
  border-radius: 6px;
  background: rgba(20, 97, 126, 0.05);
  border: 1px solid rgba(20, 97, 126, 0.2);
  &.larger {
    height: 240px;
  }
}

.f-list-item {
  border-radius: 4px;
  margin: 4px;
  cursor: pointer;
  min-height: 32px;
  line-height: 32px;
  text-align: center;
  background: rgba(20, 97, 126, 0.05);
}
</style>
