const $axios = require('axios')
import { log } from '@/decorators'
/* eslint-disable */
export default {
  endPoint: [`/${process.env.VUE_APP_API_VERSION}/auth`],
  client: $axios.create({
    withCredentials: true,
    baseURL: process.env.VUE_APP_API_URL,
    timeout: process.env.VUE_APP_API_TIMEOUT
  }),
  @log('AUTH - API - CREATE', 3)
  async create(params) {
    const res = await this.client.post(`${this.endPoint[0]}`, params)
    return res.data
  },
  @log('AUTH - API - DELETE', 3)
  async delete() {
    const res = await this.client.delete(`${this.endPoint[0]}`)
    return res.data
  },
  async authMe() {
    const res = await this.client.get(`${this.endPoint[0]}/me`)
    return res.data
  }
}
