<template>
  <div>
    <v-row v-if="selected.length === 0" no-gutters class="pa-2">
      <v-col v-if="proofMode" class="d-flex child-flex pa-1" :cols="12">
        <b class="overline">1 - Choisir la photo de la preuve</b>
      </v-col>
      <v-col
        v-for="(p, i) in sortedPhotos"
        :key="p.originalIdx"
        class="d-flex child-flex pa-1"
        :cols="columns"
      >
        <v-img
          :src="p.url"
          aspect-ratio="1"
          contain
          class="grey lighten-2"
          @click="openGallery(i)"
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
          <div class="number">
            <v-checkbox
              v-if="proofMode"
              v-model="selected"
              :value="p.originalIdx"
              @click.native="$event.stopPropagation()"
              @change="setProofPhotos"
              class="ml-2 mb-1"
              hide-details
              dense
            >
              <template v-slot:label>
                <small class="primary--text"
                  ><b>Choisir la photo</b></small
                >
              </template>
            </v-checkbox>
            <b v-else class="primary--text">{{p.category}}</b>
          </div>
        </v-img>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col class="d-flex child-flex pa-1" :cols="12">
        <v-img
          :src="photos[selected[0]]"
          aspect-ratio="1"
          contain
          class="grey lighten-2"
          @click="openGallery(selected[0])"
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
          <div v-if="role !== 'VALIDATOR'" class="number">
            <v-checkbox
              v-if="proofMode"
              v-model="selected"
              :value="selected[0]"
              @click.native="$event.stopPropagation()"
              @change="setProofPhotos"
              class="ml-2 mb-1"
              hide-details
              dense
            >
              <template v-slot:label>
                <small class="primary--text"
                  ><b>{{ selected[0] + 1 }}</b></small
                >
              </template>
            </v-checkbox>
          </div>
        </v-img>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import { get, map, orderBy } from 'lodash-es'
export default {
  name: 'TSGalleryWidget',
  props: {
    photos: {
      type: Array,
      required: true,
    },
    columns: {
      type: Number,
      default: 12,
    },
    splitted: {
      type: Boolean,
      default: false,
    },
    proofMode: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selected: [],
      classes: []
    }
  },
  computed: {
    ...mapGetters({
      currentProof: 'matches/currentProof',
      picturesClasses: 'fluxItems/picturesClasses'
    }),
    role() {
      return get(this.user, 'customData.role')
    },
    sortedPhotos() {
      const pics = []
      this.photos.forEach((p, i) => {
          const {label, weight} = this.getCategoryLabel(get(this.picturesClasses, `[${i}].category`))
          pics[i] = {
            category: label,
            score: get(this.picturesClasses, `[${i}].score`) || 0,
            categoryWeight: weight,
            url: p,
            originalIdx: i
          }
        });

      return orderBy(pics,['categoryWeight', 'score'],['desc', 'desc'])
    }
  },
  watch: {
    proofMode() {
      this.selected = []
    },
    currentProof: {
      handler(newVal) {
        this.selected = newVal.photos
      },
      immediate: true,
    }
  },
  methods: {
    ...mapMutations({
      setProofProperty: 'matches/SET_PROOF_PROPERTY',
    }),
    openGallery(i) {
      this.$root.$emit('toggleGallery', {
        open: true,
        currentIndex: i,
        photos: this.proofMode ? this.photos : map(this.sortedPhotos,'url'),
      })
    },
    setProofPhotos() {
      this.setProofProperty({ key: 'photos', value: this.selected })
    },
    getCategoryLabel(cat) {
      switch (cat) {
        case 'indoor':
          return {label: 'intérieur', weight: 1}
        case 'outdoor':
          return {label: 'extérieur', weight: 2}
        case 'other':
          return {label: 'autre', weight: 0}
        default:
          return {label: '...', weight: 0}
      }
    }
  },
}
</script>
<style lang="scss" scoped>
.v-image {
  cursor: pointer;
  .number {
    background: rgba(255, 255, 255, 0.6);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
  }
}
</style>
