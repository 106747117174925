import Vue from 'vue'
import { map } from 'lodash-es'
import { log, logSync } from '@/decorators'
import { findIndex } from 'lodash-es'
import fluxApi from '@/api/fluxApi'
import clientApi from '@/api/clientApi'

// initial state
const state = () => ({
  flux: [],
  currentFlux: null,
  totalFlux: 0,
  totalPages: 0,
  currentPage: 0,
  priorityFilters: [],
  // hardcoded for now
  filterColumns: [
    { value: 'type', text: 'Type' },
    { value: 'geom', text: 'Indice géométrique' },
    { value: '"geomCenter"', text: 'Indice géométrique (centre)' },
    { value: '"geomType"', text: "Type d'indice géometrique" },
    { value: 'source', text: "Source de l'annonce" },
    { value: '"structuredData"->>\'adType\'', text: "Type d'annonce" },
    { value: '"structuredData"->>\'propertyType\'', text: 'Type de propriété' },
    {
      value: '("structuredData"->>\'publicationDate\')::date',
      text: 'Date de publication',
    },
    {
      value: '("structuredData"->>\'isActive\')::BOOLEAN',
      text: 'Annonce active',
    },
    { value: '("structuredData"->>\'isPro\')::BOOLEAN', text: 'Annonce pro' },
    { value: '("structuredData"->>\'area\')::NUMERIC', text: 'Surface' },
    {
      value: '("structuredData"->>\'landArea\')::NUMERIC',
      text: 'Surface de terrain',
    },
    { value: '("structuredData"->>\'price\')::NUMERIC', text: 'Prix de vente' },
    {
      value: '("precomputeInfo"->>\'hasPerfectMatch\')::BOOLEAN',
      text: 'Avec perfect match',
    },
    {
      value: '("precomputeInfo"->>\'count\')::NUMERIC',
      text: 'Nb perfect match',
    },
    {
      value: '("precomputeInfo"->>\'mayHaveDuplicate\')::BOOLEAN',
      text: 'Avec doublons potentiels',
    },
    {
      value: '("precomputeInfo"->>\'duplicateToCheckCount\')::NUMERIC',
      text: 'Nb doublons à verifier',
    },
    {
      value: '("precomputeInfo"->>\'outdoorPicturesCount\')::NUMERIC',
      text: "Avec nb photos d'extérieur",
    },
    {
      value: '"treatedOrSkippedBy"',
      text: 'Opérateurs (pour validation)',
    },
    {
      value: '"treatedOrSkippedAt"',
      text: 'Date de traitement',
    },
    {
      value: '"treatedOrSkippedAt"',
      text: 'Date de traitement',
    },
    {
      value: '"createdAt"',
      text: "Date d'insertion",
    },
    {
      value: 'id',
      text: "id de l'item",
    },
    {
      value: 'state',
      text: 'État',
    },
    { value: '"codeDept"', text: 'Département' },
    { value: '"codeInsee"', text: 'Code Insee' },
    { value: '"structuredData"->>\'internalRef\'', text: 'référence interne' },
  ],
})

// getters
const getters = {
  flux: (state) => {
    return state.flux
  },
  currentFlux: (state) => {
    return state.currentFlux
  },
  totalFlux: (state) => {
    return state.totalFlux
  },
  totalPages: (state) => {
    return state.totalPages
  },
  currentPage: (state) => {
    return state.currentPage
  },
  filterColumns: (state) => {
    return state.filterColumns
  },
  priorityFilters: (state) => {
    return state.priorityFilters
  }
}
/* eslint-disable */
// actions
const actions = {
  @log('STORE - ACTION - FLUX - GET FLUX', 3)
  async getFlux({ commit, state }, params) {
    const res = await fluxApi.getFlux(params)
    commit('SET_STATE', res)
  },  
  @log('STORE - ACTION - FLUX - GET FLUX DETAILS', 3)
  async getFluxDetails({ commit, state }, params) {
    const res = await fluxApi.getFluxDetails(params)
    commit('SET_CURRENT_FLUX', res.flux)
  },
  @log('STORE - ACTION - FLUX - SET USERS IN FLUX', 3)
  async setUsersInFlux({ commit, state }, users) {
    const res = await fluxApi.updateFlux({
      flux: {
        id: state.currentFlux.id,
        users: map(users, 'id')
      }
    })
    if (res) {
      commit('SET_USERS_IN_FLUX', users)
    }
  },
  @log('STORE - ACTION - FLUX - SET PRIOTY FILTERS IN FLUX', 3)
  async setPriorityFiltersInFlux({ commit, state }, priorityFilters) {
    const res = await fluxApi.updateFlux({
      flux: {
        id: state.currentFlux.id,
        'settings.priorityFilters': priorityFilters
      }
    })
    if (res) {
      commit('SET_PRIORITY_FILTERS_IN_FLUX', priorityFilters)
    }
  },
  @log('STORE - ACTION - FLUX - CREATE FLUX', 3)
  async createFlux({ commit, state }, params) {
    const res = await fluxApi.createFlux(params)
    commit('ADD_NEW_FLUX', res.flux)
    return res.flux
  },
  @log('STORE - ACTION - FLUX - UPDATE FLUX', 3)
  async updateFlux({ commit, state }, params) {
    if (params.flux.users) {
      params.flux.users = map(params.flux.users, 'id')
    }
    const flux = await fluxApi.updateFlux(params)
    commit('SET_FLUX', flux)
    return flux
  },
  @log('STORE - ACTION - FLUX - DELETE FLUX', 3)
  async deleteFlux({ commit, state }, params) {
    const res = await fluxApi.deleteFlux(params)
    commit('REMOVE_FLUX_IN_LIST', params.id)
    return res
  },
  @log('STORE - ACTION - FLUX - DELETE FLUX', 3)
  async resetAllFluxItems({ commit, state }) {
    const res = await fluxApi.resetFlux({
      flux: {
        id: state.currentFlux.id
      }
    })
    return res
  },
  @log('STORE - ACTION - FLUX - GET PRIORITY FILTERS', 3)
  async getPriorityFilters({ commit, state }) {
    const filters = await clientApi.get(`flux/priorityfilters`, {})
    commit('SET_PRIORITY_FILTERS', filters)
    return filters
  }
}

// mutations
const mutations = {
  @logSync('STORE - MUTATION - FLUX - SET FLUX', 4)
  SET_STATE(state, res) {
    state.flux = res.flux
    state.totalFlux = res.totalItems
    state.totalPages = res.totalPages
    state.currentPage = res.currentPage
  },
  @logSync('STORE - MUTATION - FLUX - SET CURRENT FLUX', 4)
  SET_CURRENT_FLUX(state, flux) {
    state.currentFlux = flux
  },
  @logSync('STORE - MUTATION - FLUX - ADD USER IN CURRENT FLUX', 4)
  SET_USERS_IN_FLUX(state, users) {
    state.currentFlux.users.splice(0)
    state.currentFlux.users = state.currentFlux.users.concat(users)
  },
  @logSync('STORE - MUTATION - FLUX - SET PRIORITY FILTERS IN FLUX', 4)
  SET_PRIORITY_FILTERS_IN_FLUX(state, priorityFilters) {
    state.currentFlux.settings.priorityFilters = priorityFilters
  },
  @logSync('STORE - MUTATION - FLUX - ADD FLUX IN LIST', 4)
  ADD_NEW_FLUX(state, flux) {
    state.flux.unshift(flux)
  },
  @logSync('STORE - MUTATION - FLUX - SET FLUX', 4)
  SET_FLUX(state, flux) {
    const index = findIndex(state.flux, f => { return f.id === flux.id })
    if (index > -1) {
      Vue.set(state.flux, index, flux)
    }
    state.currentFlux = flux
  },
  @logSync('STORE - MUTATION - FLUX - SET PRIORITY FILTERS', 4)
  SET_PRIORITY_FILTERS(state, filters) {
    state.priorityFilters = filters
  },
  @logSync('STORE - MUTATION - FLUX - REMOVE FLUX IN LIST', 4)
  REMOVE_FLUX_IN_LIST(state, id) {
    const index = findIndex(state.flux, f => { return f.id === id })
    state.flux.splice(index, 1)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
