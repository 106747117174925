import Vue from 'vue'
import { map } from 'lodash-es'
import { log, logSync } from '@/decorators'
import { findIndex } from 'lodash-es'
import teamApi from '@/api/teamApi'

// initial state
const state = () => ({
  currentTeam: null,
  teams: [],
  totalTeams: 0,
  totalPages: 0,
  currentPage: 0,
})

// getters
const getters = {
  currentTeam: (state) => {
    return state.currentTeam
  },
  teams: (state) => {
    return state.teams
  },
  totalTeams: (state) => {
    return state.totalTeams
  },
  totalPages: (state) => {
    return state.totalPages
  },
  currentPage: (state) => {
    return state.currentPage
  },
}
/* eslint-disable */
// actions
const actions = {
  @log('STORE - ACTION - TEAMS - GET TEAMS', 3)
  async getTeams({ commit, state }, params) {
    const res = await teamApi.getTeams(params)
    commit('SET_STATE', res)
  },
  @log('STORE - ACTION - TEAMS - GET TEAM DETAILS', 3)
  async getTeamDetails({ commit, state }, params) {
    const res = await teamApi.getTeamDetails(params)
    commit('SET_CURRENT_TEAM', res.team)
  },
  @log('STORE - ACTION - TEAMS - SET USERS IN TEAM', 3)
  async setUsersInTeam({ commit, state }, users) {
    const res = await teamApi.updateTeam({
      team: {
        id: state.currentTeam.id,
        users: map(users, 'id')
      }
    })
    if (res) {
      commit('SET_USERS_IN_TEAM', users)
    }
  },
  @log('STORE - ACTION - TEAMS - SET MANAGER', 3)
  async setManager({ commit, state }, managerId) {
    const res = await teamApi.updateTeam({
      team: {
        id: state.currentTeam.id,
        manager: managerId
      }
    })
    if (res) {
      commit('SET_MANAGER', managerId)
    }
  },
  @log('STORE - ACTION - TEAMS - CREATE TEAM', 3)
  async createTeam({ commit, state }, params) {
    const res = await teamApi.createTeam(params)
    commit('ADD_NEW_TEAM', res.team)
    return res.team
  },
  @log('STORE - ACTION - TEAMS - UPDATE TEAM', 3)
  async updateTeam({ commit, state }, params) {
    const res = await teamApi.updateTeam(params)
    commit('SET_TEAM_IN_LIST', params.team)
    return res
  },
  @log('STORE - ACTION - TEAMS - DELETE TEAM', 3)
  async deleteTeam({ commit, state }, params) {
    const res = await teamApi.deleteTeam(params)
    commit('REMOVE_TEAM_IN_LIST', params.id)
    return res
  }
}

// mutations
const mutations = {
  @logSync('STORE - MUTATION - TEAMS - SET TEAMS', 4)
  SET_STATE(state, res) {
    state.teams = res.teams
    state.totalTeams = res.totalItems
    state.totalPages = res.totalPages
    state.currentPage = res.currentPage
  },
  @logSync('STORE - MUTATION - TEAMS - SET CURRENT TEAM', 4)
  SET_CURRENT_TEAM(state, team) {
    state.currentTeam = team
  },
  @logSync('STORE - MUTATION - TEAM - ADD USER IN CURRENT TEAM', 4)
  SET_USERS_IN_TEAM(state, users) {
    state.currentTeam.users.splice(0)
    state.currentTeam.users = state.currentTeam.users.concat(users)
  },
  @logSync('STORE - MUTATION - TEAM - ADD USER IN CURRENT TEAM', 4)
  SET_MANAGER(state, managerId) {
    state.currentTeam.manager = managerId
  },
  @logSync('STORE - MUTATION - TEAMS - ADD TEAM IN LIST', 4)
  ADD_NEW_TEAM(state, team) {
    state.teams.unshift(team)
  },
  @logSync('STORE - MUTATION - TEAMS - SET TEAM IN LIST', 4)
  SET_TEAM_IN_LIST(state, team) {
    const index = findIndex(state.teams, u => { return u.id === team.id })
    Vue.set(state.teams, index, team)
  },
  @logSync('STORE - MUTATION - TEAMS - REMOVE TEAM IN LIST', 4)
  REMOVE_TEAM_IN_LIST(state, id) {
    const index = findIndex(state.teams, u => { return u.id === id })
    state.teams.splice(index, 1)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
