<template>
  <v-dialog
    v-model="confirmation.active"
    content-class="e-dialog"
    persistent
    max-width="700"
  >
    <v-card class="pa-3">
      <v-card-title>
        <h2 class="headline">{{ confirmation.title }}</h2>
        <v-spacer></v-spacer>
        <v-btn icon @click.stop="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text v-if="confirmation.body">
        {{ confirmation.body }}
        <v-textarea v-if="confirmation.showReason" v-model="reason">
        </v-textarea>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          class="indigo--text font-weight-regular text-none text--darken-4"
          text
          @click.stop="cancel"
        >
          {{
            confirmation.cancelTxt ? confirmation.cancelTxt : 'Annuler'
          }}</v-btn
        >
        <v-btn
          class="text-none font-weight-regular"
          color="secondary"
          :ripple="false"
          @click.stop="confirm"
        >
          {{ confirmation.btnTxt ? confirmation.btnTxt : 'Confirmer' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'TSConfirmDialog',
  data() {
    return {
      reason: '',
    }
  },
  computed: {
    confirmation() {
      return this.$store.getters['confirmation/confirmation']
    },
  },
  methods: {
    close() {
      this.reason = ''
      this.$store.commit('confirmation/CONFIRMATION_CLOSE')
    },
    confirm() {
      this.confirmation.resolve({
        reason: this.reason,
      })
      this.reason = ''
      this.$store.commit('confirmation/CONFIRMATION_CLOSE')
    },
    cancel() {
      this.reason = ''
      this.confirmation.resolve(false)
      this.$store.commit('confirmation/CONFIRMATION_CLOSE')
    },
  },
}
</script>
<style></style>
