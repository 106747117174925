const CONFIRMATION_OPEN = 'CONFIRMATION_OPEN'
const CONFIRMATION_CLOSE = 'CONFIRMATION_CLOSE'

// initial state
const state = () => ({
  confirmation: {
    active: false,
    title: '',
    body: '',
    showReason: false,
    resolve: null,
    reject: null,
  },
})

// getters
const getters = {
  confirmation: (state) => state.confirmation,
}
/* eslint-disable */
// actions
const actions = {
    askConfirmation: ({ commit }, { title, body, showReason, btnTxt, cancelTxt }) => {
    return new Promise((resolve, reject) => {
      commit(CONFIRMATION_OPEN, {
        active: true,
        title,
        body,
        showReason,
        btnTxt,
        cancelTxt,
        resolve,
        reject
      })
    })
  }
}

// mutations
const mutations = {
  [CONFIRMATION_OPEN]: (state, payload) => {
    Object.assign(state.confirmation, payload)
  },
  [CONFIRMATION_CLOSE]: (state) => {
    state = Object.assign(state.confirmation, {
      active: false,
      title: '',
      body: '',
      resolve: null,
      reject: null
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}