<template>
  <div>
    <v-row v-if="ad" class="pa-0 ad" no-gutters>
      <v-col
        v-for="(p, i) in structuredProperties"
        :key="i"
        cols="12"
        class="property px-5 py-3"
      >
        <v-row no-gutters>
          <b>{{ p.text }}</b>
          <v-spacer />
          <span v-html="p.value" />
          <v-btn
            small
            v-if="p.editable"
            @click="edit(p)"
            class="ml-2 mr-0"
            icon
          >
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-else class="pa-0" no-gutters>
      <v-col cols="12" class="property px-5 py-3">
        Aucune annonce sélectionnée
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import { format, parseISO } from 'date-fns'
import { fluxItemsStates } from '@/helpers/fluxItemsHelpers'

export default {
  name: 'TSAdWidget',
  props: {
    ad: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    structuredData() {
      return this.ad.structuredData
    },
    rawData() {
      return this.ad.rawData
    },
    structuredProperties() {
      const _properties = []
      const s = this.structuredData
      const r = this.rawData

      _properties.push({
        text: 'Source :',
        value: `${this.ad.source} - <a href="${s.sourceUrl}" target="blank" rel="noreferrer">${this.ad.sourceId}</a>`,
      })

      _properties.push({
        text: 'État :',
        value: `<b style="color:${fluxItemsStates[this.ad.state].color};">${
          fluxItemsStates[this.ad.state].label
        }</b>`,
      })

      _properties.push({
        text: 'Code Insee:',
        value: `${this.ad.codeInsee} (${this.ad.structuredData.city})`,
        editable: true,
        key: 'codeInsee',
      })

      if (
        s.publicationDate &&
        s.publicationDate !== '1970-01-01T00:00:00.000Z'
      ) {
        _properties.push({
          text: 'Annonce publiée le :',
          value: format(parseISO(s.publicationDate), 'dd/MM/yyyy'),
        })
      }

      if (s.title) {
        _properties.push({ text: 'Titre :', value: s.title })
      }

      if (s.price) {
        _properties.push({ text: 'Prix :', value: `${s.price} €` })
      }

      if (s.area) {
        _properties.push({
          text: 'Superficie habitable :',
          value: `${s.area} m²`,
          editable: true,
          key: 'structuredData.area',
        })
      }

      //if (s.landArea) {
      _properties.push({
        text: 'Superficie du terrain :',
        value: `${s.landArea} m²`,
        editable: true,
        key: 'structuredData.landArea',
      })
      //}

      if (s.description) {
        _properties.push({
          text: 'Description :',
          value: s.description,
        })
      }

      if (this.ad.source === 'bienici') {
        if (typeof r.roomsQuantity === 'number') {
          _properties.push({
            text: 'Nombre de pièce(s) :',
            value: r.roomsQuantity,
          })
        }

        if (typeof r.hasGarden !== 'undefined') {
          _properties.push({
            text: 'Jardin :',
            value: r.hasGarden ? 'oui' : 'non',
          })
        }

        if (typeof r.hasPool !== 'undefined') {
          _properties.push({
            text: 'Piscine :',
            value: r.hasPool ? 'oui' : 'non',
          })
        }

        if (typeof r.hasTerrace !== 'undefined') {
          _properties.push({
            text: 'Terrasse :',
            value: r.hasTerrace ? 'oui' : 'non',
          })
        }

        if (typeof r.hasConservatory !== 'undefined') {
          _properties.push({
            text: 'Véranda :',
            value: r.hasConservatory ? 'oui' : 'non',
          })
        }

        if (typeof r.hasAirConditioning !== 'undefined') {
          _properties.push({
            text: 'Climatisé :',
            value: r.hasAirConditioning ? 'oui' : 'non',
          })
        }
      }

      return _properties
    },
  },
  methods: {
    ...mapActions({
      updateFluxItem: 'fluxItems/updateFluxItem',
    }),
    edit(property) {
      this.$store
        .dispatch('confirmation/askConfirmation', {
          title: "Editer l'annonce",
          body: `${property.text}`,
          showReason: true,
        })
        .then(async (confirmation) => {
          if (confirmation) {
            const fluxItem = {
              id: this.ad.id,
              [property.key]: confirmation.reason,
            }

            if (property.key === 'codeInsee') {
              fluxItem.geomType = 'cityOrArrondissement'
            }

            await this.updateFluxItem({ fluxItem })
          }
        })
    },
  },
}
</script>
<style lang="scss" scoped>
.property {
  font-size: 12px;
  &:nth-of-type(odd) {
    background: #f2f4f5;
  }
}

.subtitle-1 {
  font-size: 12px !important;
}
</style>
<style lang="scss">
.ad {
  .ad-location {
    display: inline-block;
    background: #d04437;
    color: white;
    font-weight: bold;
    padding: 0px 5px;
  }

  .ad-useless {
    text-decoration: line-through;
  }
}
</style>
